import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { AuthContext } from '../context/UserContext';
import { Api } from '../models/api';
import PaywallPopup from '../popups/PaywallPopup/PaywallPopup';
import { auth, db } from './firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { removeToken } from './authHelpers';

interface ProtectedRouteInterface {
	children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteInterface> = (props) => {
	const navigate = useNavigate();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);

	const [userData, setUserData] = useState<Api.User.Res.LoggedInUser | any | undefined>();
	const [isLoading, setIsLoading] = useState(false);
	const [openPaywall, setOpenPaywall] = useState<boolean>(false);
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);
	const currentUser = auth.currentUser;

	const breederRestrictions = async () => {
		if (!currentUser?.uid) return;
		if (!userData) return;
		if (!userData.isBuyer && userData.verifiedStatus && userData.verifiedStatus !== 'approved') {
			console.log({ userData });
			removeToken('protectedRoute');
			await signOut(auth);
			return;
		}
		if (!userData.isBuyer && !userData.isSubscribed) {
			setOpenPaywall(true);
		}
		if (!userData.isBuyer && userData.isSubscribed && !(userData.onboarding_registration || userData.onboardedAt)) {
			//!userData.stripeAccountId && !userData.stripeAccountLink
			// redirect to breeder onboarding payout setup page
			console.log(
				!userData.isBuyer &&
					userData.isSubscribed &&
					!(userData.onboarding_registration || userData.onboardedAt)
			);
			navigate(`/breeder/onboarding`);
		}
	};

	// const checkUser = () => {
	// 	if (!currentUser?.uid) {
	// 		setIsLoggedIn(false);
	// 	} else {
	// 		verifyUserToken();
	// 	}
	// };

	const handleUser = (user: Api.User.Res.LoggedInUser) => {
		console.log(user);
		setUserData(user);
	};

	useEffect(() => {
		let unsubscribe: any;
		(async () => {
			if (!currentUser) return;
			setIsLoading(true);
			try {
				const breederRef = doc(db, 'Breeders', currentUser.uid);
				const breeder = await getDoc(breederRef);
				if (breeder.exists()) {
					setUserData(breeder.data());
					unsubscribe = onSnapshot(breederRef, (snapshot) => {
						setUserData(snapshot.data());
					});
				} else {
					console.log('user !');
					const userRef = doc(db, 'Users', currentUser.uid);
					const user = await getDoc(userRef);
					if (user.exists()) {
						setUserData(user.data());
						console.log({ data: user.data() });
					}
				}
			} catch (error) {
				// removeToken();
				// await signOut(auth);
				message.error('Unauthorized.');
				navigate('/login');
			} finally {
				setIsLoading(false);
				setIsLoggedIn(true);
			}
		})();
		return () => {
			if (typeof unsubscribe === 'function') unsubscribe();
		};
		// eslint-disable-next-line
	}, [currentUser, forceUpdate]);

	useEffect(() => {
		breederRestrictions();
		// eslint-disable-next-line
	}, [userData, currentUser, forceUpdate]);

	return isLoggedIn ? (
		<AuthContext.Provider value={{ user: userData, setUser: handleUser, isLoading }}>
			{props.children}
			<PaywallPopup
				opened={openPaywall}
				onCancel={() => setOpenPaywall(false)}
				onForceCb={() => setForceUpdate(!forceUpdate)}
			/>
		</AuthContext.Provider>
	) : (
		<Navigate to="/login" replace />
	);
};

export default ProtectedRoute;
