import { AUTH_TOKEN, SELECTED_CONVERSATION } from './constant';
// import { auth } from './firebase';

export const getToken = (): string | undefined => {
	// return auth.currentUser?.uid || '';
	return localStorage.getItem(AUTH_TOKEN) || '';
};

export const setToken = (token: string) => {
	if (token) {
		localStorage.setItem(AUTH_TOKEN, token);
	}
};

export const removeToken = (src: any) => {
	console.log({ src });
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(SELECTED_CONVERSATION);
};
