import React, { useEffect, useState } from 'react';
import './BreederStorefront.scss';
import BreederCard from '../BreederCard/BreederCard';
import { Col, Empty, Input, Row, Select, message } from 'antd';
import BreederCategoryCard from '../BreederCategoryCard/BreederCategoryCard';
import AdvancedBreederStorefrontFilterDrawer from '../../drawers/AdvancedBreederStorefrontFilter/AdvancedBreederStorefrontFilterDrawer';
import { categories } from '../../utils/constant';
import { getToken } from '../../utils/authHelpers';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
// import { useGetUser } from '../../utils/useGetUser';


const Fade = require('react-reveal/Fade');

interface BreederStorefrontProps {
	breederId?: string;
}

type SortByTypes = 'asc' | 'desc' | '';

const BreederStorefront: React.FC<BreederStorefrontProps> = ({ breederId }) => {
	const location = useLocation();
	// const { user }: any = useGetUser();
	const queryParams = new URLSearchParams(location.search);
	const paramCategoryId: string | null = queryParams.get('categoryId');
	const [listOfBreeders, setListOfBreeders] = useState<any[]>([]);
	const [searchValue, setSearchValue] = useState<string>('');
	const [openAdvancedFilter, setOpenAdvancedFiter] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] = useState<number>(2);
	const [sortBy, setSortBy] = useState<SortByTypes>('');
	const [showPrice, setShowPrice] = useState(true);
	const { pathname } = useLocation();
	const [loading, setLoading] = useState(false);

	const loadListOfBreeders = async () => {
		try {
			setLoading(true);
			const selectedCollection: string =
				categories.find((item) => item.id === selectedCategory)?.collection ?? '';
			if (selectedCollection === 'pupProfile') {
				setShowPrice(true);
			} else {
				setShowPrice(false);
			}
			const ref = collection(db, selectedCollection);
			let q = query(ref, orderBy('date', 'desc'));

			if (breederId) {
				q = query(ref, where('uid', '==', breederId), orderBy('date', 'desc'));
			}

			const data = await getDocs(q);
			const tmp: any[] = [];
			data.forEach((element) => {
				const data: any = element.data();
				if (!data.isSold) tmp.push({ ...data, id: element.id });
			});
			setListOfBreeders(tmp);
			setLoading(false);
		} catch (error) {
			console.log(error);
			message.error(`Something wen't wrong in getting list of breeders.`);
			setLoading(false);
		}
	};

	useEffect(() => {
		const loadParamsCategoryId = () => {
			if (!paramCategoryId) {
				if (!selectedCategory) {
					setSelectedCategory(0);
				}
				return;
			}
			const parsedCategoryId: number = parseInt(paramCategoryId);
			setSelectedCategory(parsedCategoryId);
		};
		loadParamsCategoryId();
	}, [paramCategoryId, selectedCategory]);

	useEffect(() => {
		loadListOfBreeders();
		// eslint-disable-next-line
	}, [selectedCategory, breederId]);

	const renderListOfBreeders = () => {
		if (!Array.isArray(listOfBreeders) || !listOfBreeders.length) return <Empty />;
		let listOfBreedersData = listOfBreeders.filter((data) => !data.isDeleted);

		if (searchValue) {
			const searchItem = listOfBreedersData[0]?.dogType ? 'dogType' : 'name';
			listOfBreedersData = listOfBreedersData.filter((data) => {
				return data[searchItem]?.toLowerCase().includes(searchValue.toLowerCase());
			});
		}
		if (sortBy) {
			const searchItem = listOfBreedersData.length
				? listOfBreedersData[0]?.dogType
					? 'dogType'
					: 'name'
				: 'name';
			switch (sortBy) {
				case 'desc':
					listOfBreedersData = listOfBreedersData.sort((a, b) => b[searchItem]?.localeCompare(a[searchItem])); //name
					break;
				case 'asc':
					listOfBreedersData = listOfBreedersData.sort((a, b) => a[searchItem]?.localeCompare(b[searchItem]));
					break;
				// case 'createdDesc':
				// 	listOfBreedersData = listOfBreeders.sort(
				// 		(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				// 	);
				// 	break;
				// case 'createdAsc':
				// 	listOfBreedersData = listOfBreeders.sort(
				// 		(a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				// 	);
				// 	break;
				default:
					break;
			}
		}
		return listOfBreedersData.map((data) => (
			<Col
				key={data.id}
				xs={24}
				sm={24}
				md={breederId ? 12 : 8}
				lg={breederId ? 8 : 6}
				xl={breederId ? 8 : 6}
				xxl={breederId ? 8 : 6}
			>
				<Fade>
					<BreederCard
						dadImage={data.dadImage}
						mumImage={data.mumImage}
						showPrice={showPrice}
						id={data.id}
						pedigreePaperDam={data.pedigreePaperDam}
						pedigreePaperSire={data.pedigreePaperSire}
						name={data.name ?? data.pupName ?? data.dogType}
						description={data.description}
						category={categories.find((item) => item.id === selectedCategory)?.collection ?? ''}
						previewUrl={
							Array.isArray(data.dogImages) && data.dogImages.length
								? data.dogImages[0]
								: data.dogImage
								? data.dogImage
								: Array.isArray(data.pupsImages) && data.pupsImages.length
								? data.pupsImages[0]
								: Array.isArray(data.pupImages) && data.pupImages.length
								? data.pupImages[0]
								: data.profilePhotoURLs
								? data.profilePhotoURLs[0]
								: ''
						}
						ownerId={data.uid}
						ownerName={data.businessName}
						ownerProfileImageUrl={data.avatar ? data.avatar?.url : ''}
						pricing={data.price ?? data.animalPrice ?? data.dogPrice}
					/>
				</Fade>
			</Col>
		));
	};

	const renderCategories = () => {
		if (!Array.isArray(categories) || !categories.length) return;
		if (!getToken()) return;
		return categories.map((category) =>
			category.collection === 'PupForShowCase' && !pathname.includes('/breeder/') ? null : (
				<Col key={category.id} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
					<BreederCategoryCard
						selectedCategoryId={selectedCategory}
						onClick={(categoryId: number) => setSelectedCategory(categoryId)}
						id={category.id}
						name={category.name}
						icon={category.icon}
					/>
				</Col>
			)
		);
	};

	return (
		<div className="breederStorefront customLayoutWidth">
			<div className="breederStoreContent">
				<Row
					className={`breederStoreFilterCategory custom-width ${!breederId && getToken() ? 'relativePos' : ''} ${
						!getToken() ? 'unauthMarketplace' : ''
					}`}
					justify={'space-evenly'}
					gutter={[0, 0]}
				>
					{renderCategories()}
				</Row>
				<Row className="breederStorefrontFilters" gutter={[24, 24]}>
					<Col
						xs={getToken() ? 20 : 24}
						sm={getToken() ? 20 : 24}
						lg={!getToken() ? 18 : 20}
						xl={!getToken() ? 18 : 20}
						xxl={!getToken() ? 18 : 20}
					>
						<Input
							className="searchFilter"
							prefix={<i className="ri-search-line ri-lg primary-color-icon"></i>}
							size="large"
							value={searchValue}
							placeholder="Search for breeds"
							onChange={(e: any) => setSearchValue(e.target.value)}
						/>
					</Col>
					<Col xs={0} sm={0} lg={!getToken() ? 6 : 2} xl={!getToken() ? 6 : 3} xxl={!getToken() ? 6 : 3}>
						<Select
							placeholder={getToken() ? 'A-Z' : 'Filter'}
							className="sortBySelect"
							suffixIcon={<i className="ri-sort-desc ri-2x primary-color-icon"></i>}
							size="large"
							onChange={(e: any) => setSortBy(e)}
						>
							<Select.Option value="asc">(A - Z)</Select.Option>
							<Select.Option value="desc">(Z - A)</Select.Option>
							<Select.Option value="createdDesc">Newest</Select.Option>
							<Select.Option value="createdAsc">Oldest</Select.Option>
						</Select>
					</Col>
					{/* <Col
						xs={getToken() ? 4 : 24}
						sm={getToken() ? 4 : 24}
						className="sortFilterCta"
						lg={!getToken() ? 0 : 2}
						xl={!getToken() ? 0 : 2}
						xxl={!getToken() ? 0 : 2}
					>
						<Button onClick={() => setOpenAdvancedFiter(true)} size="large">
							<i className="ri-equalizer-line ri-lg primary-color-icon"></i>
							<Typography.Text className="sortFilterCtaTxt">Filter</Typography.Text>
						</Button>
					</Col> */}
				</Row>
				<Row
					justify={!Array.isArray(listOfBreeders) || !listOfBreeders.length ? 'center' : 'start'}
					gutter={[24, 24]}
				>
					{loading ? <div>loading</div> : renderListOfBreeders()}
				</Row>
			</div>
			<AdvancedBreederStorefrontFilterDrawer
				opened={openAdvancedFilter}
				onCancel={() => setOpenAdvancedFiter(false)}
				onForceCb={() => console.log('object')}
			/>
		</div>
	);
};

export default BreederStorefront;
