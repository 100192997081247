import React, { useEffect, useState } from 'react';
import { Col, Result, Row, Typography } from 'antd';
import StatsCard from '../../components/StatsCard/StatsCard';
import './StatsView.scss';
import dogImg from '../../assets/images/dashboard.jpg';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useGetUser } from '../../utils/useGetUser';

const StatsView: React.FC = () => {
	const { user }: any = useGetUser();
	const [count, setCount] = useState({
		total: 0,
		pups: 0,
		upcoming: 0,
		studs: 0,
		showcase: 0,
		orders: 0
	});
	const [loading, setLoading] = useState(false);
	const { total, pups, upcoming, studs, showcase, orders } = count;

	const loadStatCount = async () => {
		try {
			if (!user) return;
			setLoading(true);
			const userQuery = where('uid', '==', user.uid);
			const pupForSaleRef = collection(db, 'pupProfile');
			const qForSale = query(pupForSaleRef, userQuery);
			const pupForSaleSnapShot = await getDocs(qForSale);
			setCount((pre) => {
				return {
					...pre,
					pups: pupForSaleSnapShot.size ?? 0
				};
			});
			const pupForShowCaseRef = collection(db, 'PupForShowCase');
			const qpupForShowCaseRef = query(pupForShowCaseRef, userQuery);
			const qpupForShowCase = await getDocs(qpupForShowCaseRef);
			setCount((pre) => {
				return {
					...pre,
					showcase: qpupForShowCase.size ?? 0
				};
			});

			const studProfileRef = collection(db, 'studProfile');
			const qstudProfileRef = query(studProfileRef, userQuery);
			const qstudProfile = await getDocs(qstudProfileRef);
			setCount((pre) => {
				return {
					...pre,
					studs: qstudProfile.size ?? 0
				};
			});

			const upCommingRef = collection(db, 'UpcomingLitters');
			const qupCommingRef = query(upCommingRef, userQuery);
			const qupComming = await getDocs(qupCommingRef);
			setCount((pre) => {
				return {
					...pre,
					upcoming: qupComming.size ?? 0
				};
			});

			setCount((pre) => {
				return {
					...pre,
					total: qupComming.size + qstudProfile.size + qpupForShowCase.size + pupForSaleSnapShot.size
				};
			});

			const ordersRef = collection(db, 'Orders');
			const qOrder = query(ordersRef, where('breederId', '==', user.uid));
			const ordersSnapShot = await getDocs(qOrder);
			setCount((pre) => {
				return {
					...pre,
					orders: ordersSnapShot.size ?? 0
				};
			});
		} catch (error) {
			console.log(`Something wen't wrong in getting count data.`);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadStatCount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="statsViewContainer">
			<Typography.Title className="s" level={3}>
				Stats
			</Typography.Title>
			<Row className="statsContainer" gutter={[16, 16]}>
				<Col lg={4} xl={4} xxl={4}>
					<StatsCard title="Total Listing" count={total} loading={loading && !total} />
				</Col>
				<Col lg={4} xl={4} xxl={4}>
					<StatsCard title="Orders" count={orders} loading={loading && !orders} />
				</Col>
				<Col lg={4} xl={4} xxl={4}>
					<StatsCard title="Pups for Sale" count={pups} loading={loading && !pups} />
				</Col>
				<Col lg={4} xl={4} xxl={4}>
					<StatsCard title="Upcoming Litters" count={upcoming} loading={loading && !upcoming} />
				</Col>
				<Col lg={4} xl={4} xxl={4}>
					<StatsCard title="Stud Profiles" count={studs} loading={loading && !studs} />
				</Col>
				<Col lg={4} xl={4} xxl={4}>
					<StatsCard title="Showcase" count={showcase} loading={loading && !showcase} />
				</Col>
			</Row>
			<Result
				className="dashboardFeatureInProgress"
				icon={<img src={dogImg} alt="missing" />}
				title="Upcoming Chart"
				subTitle="This functionality will be in the next release."
			/>
		</div>
	);
};

export default StatsView;
