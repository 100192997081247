import React from 'react';
import './ViewProductDrawer.scss';
import { DrawerModel } from '../../models/DrawerModel';
import { Col, Drawer, Row, Typography } from 'antd';
import BreederCard from '../../components/BreederCard/BreederCard';
import { useGetUser } from '../../utils/useGetUser';
import { randomVector } from '../../utils/randomVector';

interface ViewProductDrawerProps extends DrawerModel {
	animal: any;
}

const ViewProductDrawer: React.FC<ViewProductDrawerProps> = ({ opened, onCancel, animal }) => {
	const { user }: any = useGetUser();
	const renderAnimalData = () => {
		if (!user || user.isBuyer) return;
		if (!animal) return;
		return (
			<Row align={'middle'} justify={'center'}>
				<Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
					<BreederCard
						id={animal.id}
						name={animal.name ?? animal.pupName}
						previewUrl={
							Array.isArray(animal.dogImages) && animal.dogImages.length
								? animal.dogImages[0]
								: animal.dogImage
								? animal.dogImage
								: Array.isArray(animal.pupsImages) && animal.pupsImages.length
								? animal.pupsImages[0]
								: Array.isArray(animal.pupImages) && animal.pupImages.length
								? animal.pupImages[0]
								: require(`../../assets/images/vectors/${randomVector}.png`)
						}
						ownerId={user.id}
						ownerName={user.businessName}
						ownerProfileImageUrl={user.avatar ? user.avatar : ''}
						pricing={animal.price ?? animal.animalPrice}
					/>
				</Col>
			</Row>
		);
	};
	return (
		<Drawer width={512} title="View Product" className="viewProductDrawer" open={opened} onClose={onCancel}>
			<Typography.Paragraph className="viewProductSubTxt">
				On the market, it would appear like this.
			</Typography.Paragraph>
			{renderAnimalData()}
		</Drawer>
	);
};

export default ViewProductDrawer;
