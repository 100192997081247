const statusText = (status: string): string => {
	let statusText: string = '';
	switch (status) {
		case 'Pending':
			statusText = 'Pending';
			break;
		case 'IN_TRANSIT':
			statusText = 'In Transit';
			break;
		case 'Delivered':
			statusText = 'Delivered';
			break;
		case 'Cancelled':
			statusText = 'Cancelled';
			break;
		case 'Completed':
			statusText = 'Completed';
			break;
		case 'Refunded':
			statusText = 'Refunded';
			break;
		case 'Received':
			statusText = 'Received';
			break;
		default:
			break;
	}
	return statusText;
};

export default statusText;
