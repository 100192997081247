import React, { useState } from 'react';
import './BreederOnboardingPayoutpage.scss';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import { Button, message } from 'antd';
import axios from 'axios';
import { BEARER, FIREBASE_BASE_URL } from '../../utils/constant';
import { auth, db } from '../../utils/firebase';
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { useSubUser } from '../../utils/useSubUser';
import { useNavigate } from 'react-router-dom';

const BreederOnboardingPayoutpage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { user }: any = useSubUser();
    const navigate = useNavigate();

    const startOnboarding = async () => {
        if (!user || typeof user?.email !== 'string') return;

        // Open the new window immediately for better compatibility with mobile
        const newWindow = window.open(
            '', // Open a blank window to avoid pop-up blocking
            '_blank',
            `width=600,height=600,left=${(window.innerWidth - 600) / 2},top=${(window.innerHeight - 600) / 2}`
        );

        setIsLoading(true);
        try {
            const idToken = await auth.currentUser?.getIdTokenResult(true);
            const res = (
                await axios.get(`${FIREBASE_BASE_URL}/stripe/connect/onboarding`, {
                    headers: {
                        authorization: `${BEARER} ${idToken?.token ?? ''}`
                    }
                })
            ).data;

            if (res && newWindow) {
                if (!res.url) return;

                // Update the newly opened window's location
                newWindow.location.href = `${res.url}`;

                const checkClosedInterval = setInterval(async () => {
                    if (newWindow && newWindow.closed) {
                        clearInterval(checkClosedInterval);
                        console.log('Child window has been closed');
                
                        const stripeRef = collection(db, 'StripeAccounts');
                        const q = query(stripeRef, where('userId', '==', user.uid));
                        const snapShot = await getDocs(q);
                        let data: any = {};
                        snapShot.forEach((element) => {
                            data = element.data();
                        });
                
                        // Ensure that payouts are enabled and charges are enabled before proceeding
                        if (data.id && data.payoutsEnabled === true && data.chargesEnabled === true) {
                            const userRef = doc(db, 'Breeders', user.uid);
                            await updateDoc(userRef, {
                                onboarding_registration: true,
                                onboardedAt: serverTimestamp()
                            });
                            message.success('Successfully created');
                            navigate('/dashboard');
                        } else {
                            message.error('ERROR! Please make you have completed your payout account.', 20);
                        }
                
                        setIsLoading(false);
                    }
                }, 1000); // Check every 1 second
            } else {
                newWindow?.close(); // Close the blank window if there is an issue
                setIsLoading(false);
                message.error('Something went wrong in setting up your payout account.', 20);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            message.error(`Something went wrong in creating a breeder payout account.`, 20);
            newWindow?.close(); // Close the blank window in case of an error
        }
    };

    return (
        <PrivateLayout className="breederOnboardingPayoutpage customLayoutWidth">
            <Button disabled={isLoading} loading={isLoading} onClick={startOnboarding} type="primary">
                {isLoading ? `Processing` : `Setup Payout Account`}
            </Button>
        </PrivateLayout>
    );
};

export default BreederOnboardingPayoutpage;
