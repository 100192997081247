import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyAzZNiGN1YlzpecsK7Jj-9CPj-2cikCQF4',
	authDomain: 'dog-breeder.firebaseapp.com',
	projectId: 'dog-breeder',
	storageBucket: 'dog-breeder.appspot.com',
	messagingSenderId: '735129287290',
	appId: '1:735129287290:web:c5d18807b7b1d67f5e99c3',
	measurementId: 'G-CT9H49WLY3'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage();

export { analytics, functions, auth, db, storage };
