const statusColor = (status: string): string => {
	let color: string = '';
	switch (status) {
		case 'Pending':
			color = 'warning';
			break;
		case 'IN_TRANSIT':
			color = 'processing';
			break;
		case 'Delivered':
			color = 'green';
			break;
		case 'Cancelled':
			color = 'red';
			break;
		case 'Completed':
			color = 'green';
			break;
		case 'Refunded':
			color = 'purple';
			break;
		case 'Received':
			color = 'lightgreen';
			break;
		default:
			break;
	}
	return color;
};

export default statusColor;
