export const API_URL: string =
	process.env.REACT_APP_API_URL ||
	'https://mybreedersstore-backend-d8d923592f33.herokuapp.com/api' ||
	'http://localhost:1337/api';
export const API_BASE_URL: string =
	process.env.REACT_APP_API_BASE_URL ||
	'https://mybreedersstore-backend-d8d923592f33.herokuapp.com' ||
	'http://localhost:1337';
export const FIREBASE_BASE_URL = 'https://us-central1-dog-breeder.cloudfunctions.net/api'; // 'https://us-central1-sl-mybreedersstoredev.cloudfunctions.net/api'; //https://us-central1-dog-breeder.cloudfunctions.net/api
export const AUTH_TOKEN: string = 'breederAppAuthToken';
export const SELECTED_CONVERSATION: string = 'selectedConversationId';
export const BEARER: string = 'Bearer';

export const categoriesTitle = {
	pupForShowCase: 'PupForShowCase',
	pupProfile: 'pupProfile',
	studProfile: 'studProfile',
	upcomingLitters: 'upcomingLitters'
};
export const categories = [
	{
		collection: categoriesTitle.pupForShowCase,
		id: 3,
		name: 'Pup For Showcase',
		icon: 'ri-star-line'
	},
	{
		collection: categoriesTitle.pupProfile,
		id: 2,
		name: 'Pups For Sale',
		icon: 'ri-shopping-cart-2-line'
	},
	{
		collection: categoriesTitle.studProfile,
		id: 1,
		name: 'Stud Profile',
		icon: 'ri-men-line'
	},
	{
		collection: categoriesTitle.upcomingLitters,
		id: 4,
		name: 'Upcoming Litters',
		icon: 'ri-timer-line'
	}
];

export const SUBSCRIPTION_CHECKOUT_URL =
	process.env.REACT_APP_STRIPE_SUBSCRIPTION_CHECKOUT_URL || 'https://buy.stripe.com/aEU5nF6Rq0aZ8CI9AA';
