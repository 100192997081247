import React, { useState, useEffect } from 'react';
import './DatatablesView.scss';
import { Button, Dropdown, Image, Table, Typography, message, Modal } from 'antd';
import CreateProductPopup from '../../popups/CreateProduct/CreateProductPopup';
import FormatMoney from '../../utils/FormatMoney';
import type { MenuProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import EditProductPopup from '../../popups/EditProduct/EditProductPopup';
import { categories } from '../../utils/constant';
import { Api } from '../../models/api';
import { randomVector } from '../../utils/randomVector';
import ViewProductDrawer from '../../drawers/ViewProduct/ViewProductDrawer';
import { collection, deleteDoc, doc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useGetUser } from '../../utils/useGetUser';

interface DatatablesViewProps {
	title: string;
	categoryId: number;
}

const { confirm } = Modal;

const DatatablesView: React.FC<DatatablesViewProps> = ({ title, categoryId }) => {
	const [listingData, setListingData] = useState<Api.Animal.Res.AnimalListing[]>([]);
	const [selectedAnimal, setSelectedAnimal] = useState<Api.Animal.Res.AnimalListing | null>(null);
	const [openCreateProduct, setOpenCreateProduct] = useState<boolean>(false);
	const [openEditProduct, setOpenEditProduct] = useState<boolean>(false);
	const [openViewProduct, setOpenViewProduct] = useState<boolean>(false);
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);
	const { user }: any = useGetUser();

	// Menu action functions
	const onViewProduct = () => {
		setOpenViewProduct(true);
	};

	const onEditProduct = () => {
		setOpenEditProduct(true);
	};
	let defaultPreviewImage = randomVector ? require(`../../assets/images/vectors/${randomVector}.png`) : '';

	const onDeleteProduct = () => {
		if (!selectedAnimal) return;
		confirm({
			title: 'Delete Product',
			content: 'Are you sure you want to delete this product?',
			centered: true,
			okText: 'Yes, delete',
			okType: 'danger',
			okButtonProps: {
				type: 'primary'
			},
			async onOk() {
				try {
					const selectedCollection = categories.find((item) => item.id === categoryId)?.collection;
					if (!selectedCollection || typeof selectedCollection !== 'string') {
						console.error('Selected collection is not a string:', selectedCollection);
						return;
					}
			
					if (!selectedAnimal || typeof selectedAnimal.id !== 'string' || selectedAnimal.id.trim() === '') {
						console.error('Invalid selected animal ID:', selectedAnimal);
						return;
					}
			
					const dogRef = doc(db, selectedCollection, selectedAnimal.id);
					await deleteDoc(dogRef);
					message.success(`Animal successfully deleted.`);
					setForceUpdate(!forceUpdate);
				} catch (error) {
					console.error('Error deleting animal:', error);
					message.error(`Something went wrong in deleting product.`);
				}
			},
			onCancel() {
				console.log('Cancel');
			}
			
		});
	};

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<Typography.Text className="orderTableColCta">
					<i className="ri-eye-line"></i> View
				</Typography.Text>
			),
			onClick: onViewProduct
		},
		{
			key: '2',
			label: (
				<Typography.Text className="orderTableColCta">
					<i className="ri-edit-line"></i> Edit
				</Typography.Text>
			),
			onClick: onEditProduct
		},
		{
			key: '3',
			label: (
				<Typography.Text className="orderTableColCta">
					<i className="ri-delete-bin-line"></i> Delete
				</Typography.Text>
			),
			className: 'deleteCta',
			onClick: onDeleteProduct
		}
	];

	const columns: ColumnsType<Api.Animal.Res.AnimalListing> = [
		{
			title: 'Image',
			dataIndex: '',
			key: 'id',
			render: (data: any) => {
				return (
					<div className="tableTitleContent">
						{data.mumImage ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									width: '100%',
									alignItems: 'center',
									height: '100%'
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										width: '100%',
										alignItems: 'center',
										height: '100%',
										gap:'5px'
									}}
								>
									<div style={{ position: 'relative', maxWidth: '100%' }}>
										<div
											style={{
												position: 'absolute',
												zIndex: 99,
												padding: '3px',
												backgroundColor: '#9595958e',
												borderRadius: '5px',
												width: '4rem',
												textAlign: 'center',
												left: '1rem',
												color: 'white'
											}}
										>
											dad
										</div>
										<Image
											preview={false}
											className="breederCardImg"
											width="auto"
											alt="breeder preview"
											src={data.dadImage ? data.dadImage : defaultPreviewImage}
										/>
										{/* <a
											href={data.pedigreePaperSire}
											style={{
												border: '1px solid #9595958e',
												color: '#9595958e',
												padding: '4px',
												display: 'flex',
												textAlign: 'center',
												borderRadius: '10px',
												marginTop: '1rem'
											}}
											target="_blank"
											rel="noreferrer"
										>
											Sire Pedigree Paper View
										</a> */}
									</div>
									<div style={{ position: 'relative', maxWidth: '100%' }}>
										<div
											style={{
												position: 'absolute',
												zIndex: 99,
												padding: '3px',
												backgroundColor: '#9595958e',
												borderRadius: '5px',
												width: '4rem',
												textAlign: 'center',
												left: '1rem',
												color: 'white'
											}}
										>
											mum
										</div>
										<Image
											preview={false}
											className="breederCardImg"
											width="auto"
											alt="breeder preview"
											src={data.mumImage ? data.mumImage : defaultPreviewImage}
										/>
										{/* <a
											href={data.pedigreePaperDam}
											style={{
												border: '1px solid #9595958e',
												color: '#9595958e',
												padding: '4px',
												display: 'flex',
												textAlign: 'center',
												borderRadius: '10px',
												marginTop: '1rem'
											}}
											target="_blank"
											rel="noreferrer"
										>
											Dam Pedigree Paper View
										</a> */}
									</div>
								</div>
							</div>
						) : (
							<Image
								src={
									Array.isArray(data.dogImages) && data.dogImages.length
										? data.dogImages[0]
										: data.dogImage
										? data.dogImage
										: Array.isArray(data.pupsImages) && data.pupsImages.length
										? data.pupsImages[0]
										: Array.isArray(data.pupImages) && data.pupImages.length
										? data.pupImages[0]
										: data.profilePhotoURLs
										? data.profilePhotoURLs[0]
										: require(`../../assets/images/vectors/${randomVector}.png`)
								}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: 'Name',
			dataIndex: '',
			key: 'id',
			render: (data: any) => {
				// const ageTypeInfo = data.ageType ? ` ${data.ageType}` : '';
				return (
					<div className="tableTitleBreederContent">
						<Typography.Text className="titleTxt" style={{ display: 'inline' }}>
							{data.name ?? data.pupName ?? data.dogType}
						</Typography.Text>
						<Typography.Text className="titleOwnerTxt">
						{(data.dogAge || data.age) && ` | Age: ${data.dogAge ?? data.age} ${data.ageType}`} 
						</Typography.Text>
					</div>
				);
			}
		},
		{
			title: 'Price',
			dataIndex: '',
			key: 'animalPrice',
			render: (data: any) => {
				return (
					<Typography.Text className="tablePricing">
						{FormatMoney(data.price ?? data.animalPrice ?? data.dogPrice)}
					</Typography.Text>
				);
			}
		},
		{
			title: 'Bio',
			dataIndex: '',
			key: 'aboutDog',
			ellipsis: true,
			render: (data: any) => {
				return <Typography.Text>{data.bio ?? data.aboutDog ?? data.description}</Typography.Text>;
			}
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			render: (record) => (
				<Dropdown
					overlayClassName="ordersTableColCtaDropdown"
					trigger={['click']}
					menu={{ items }}
					onOpenChange={() => setSelectedAnimal(record)}
					placement="bottom"
					arrow={{ pointAtCenter: true }}
				>
					<Button className="menuItemCta" size="small">
						<i className="ri-more-2-line"></i>
					</Button>
				</Dropdown>
			)
		}
	];

	useEffect(() => {
		loadListings();
		// eslint-disable-next-line
	}, [categoryId, forceUpdate, user]);

	const loadListings = async () => {
		if (!categoryId) return;
		try {
			if (!user || !user.uid) return;
			const selectedCollection: string = categories.find((item) => item.id === categoryId)?.collection ?? '';
			const ref = collection(db, selectedCollection);
			let q = query(ref, where('uid', '==', user.uid), orderBy('date', 'desc'));
			const data = await getDocs(q);
			const tmp: any[] = [];
			data.forEach((element) => {
				const data: any = element.data();
				tmp.push({ ...data, id: element.id });
			});
			setListingData(tmp);
		} catch (error) {
			message.error(`Something wen't wrong in getting data for datatable.`);
		}
	};

	return (
		<div className="datatableViewContainer">
			<div className="datatableViewHeader">
				<Typography.Title level={3} className="datatableViewHeaderTxt">
					{title ? title : `Datatables`}
				</Typography.Title>
				<Button onClick={() => setOpenCreateProduct(true)} type="primary">
					Create Listing
				</Button>
			</div>
			<div className="datatableTableContainer">
				<Table columns={columns} dataSource={listingData} />
			</div>
			<CreateProductPopup
				opened={openCreateProduct}
				categoryId={categoryId}
				onCancel={() => setOpenCreateProduct(false)}
				onForceCb={() => setForceUpdate(!forceUpdate)}
			/>
			{selectedAnimal && (
				<EditProductPopup
					opened={openEditProduct}
					animal={selectedAnimal}
					categoryId={categoryId}
					onCancel={() => setOpenEditProduct(false)}
					onForceCb={() => setForceUpdate(!forceUpdate)}
				/>
			)}
			{selectedAnimal && (
				<ViewProductDrawer
					opened={openViewProduct}
					animal={selectedAnimal}
					onCancel={() => setOpenViewProduct(false)}
					onForceCb={() => setForceUpdate(!forceUpdate)}
				/>
			)}
		</div>
	);
};

export default DatatablesView;
