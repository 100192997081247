import React, { useEffect, useState } from 'react';
import './PrivateNavbar.scss';
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, Typography, message } from 'antd';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getToken, removeToken } from '../../../utils/authHelpers';
// import { useUserContext } from '../../../context/UserContext';
// import logoImg from '../../../assets/images/logo.png';
import { randomVector } from '../../../utils/randomVector';
import MobileMenuDrawer from '../../../drawers/MobileMenu/MobileMenuDrawer';
import { signOut } from 'firebase/auth';
import { auth } from '../../../utils/firebase';
import { useSubUser } from '../../../utils/useSubUser';

const PrivateNavbar: React.FC = () => {
	const { user }: any = useSubUser();
	const [profileItems, setProfileItems] = useState<MenuProps['items']>([]);
	const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
	const navigate = useNavigate();

	const loadDynamicNavItems = () => {
		if (!user) return;
		let profileItemsDynamic: MenuProps['items'] = [
			{
				label: 'Profile',
				key: 'profile',
				icon: <i className="ri-settings-2-line"></i>,
				onClick: () => navigate(`/profile`)
			}
		];

		if (!user.isBuyer) {
			profileItemsDynamic.push({
				label: 'Dashboard',
				key: 'dashboard',
				icon: <i className="ri-dashboard-line"></i>,
				onClick: () => navigate(`/dashboard`)
			});
		}

		profileItemsDynamic.push({
			label: 'Logout',
			key: 'logout',
			icon: <i className="ri-logout-circle-line"></i>,
			onClick: () => logout()
		});
		setProfileItems(profileItemsDynamic);
	};

	useEffect(() => {
		loadDynamicNavItems();
		// eslint-disable-next-line
	}, [user]);

	const logout = () => {
		signOut(auth);
		removeToken("privateNavbar");
		message.success('Successfully logged out.');
		navigate('/login');
	};

	const renderNavigationItems = () => {
		if (!user) return;
		return (
			<div className="navCenter">
				<NavLink
					className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
					to="/home"
				>
					<i className={`ri-home-5-line ri-xl`}></i>
					<b>Home</b>
				</NavLink>
				<NavLink
					className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
					to="/inbox"
				>
					<i className={`ri-question-answer-line ri-xl`}></i>
					<b>Messages</b>
				</NavLink>
				{user.isBuyer && (
					<NavLink
						className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
						to="/favorites"
					>
						<i className={`ri-heart-2-line ri-xl`}></i>
						<b>Favourites</b>
					</NavLink>
				)}
				{!user.isBuyer && (
					<NavLink
						className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
						to="/dashboard"
					>
						<i className={`ri-home-5-line ri-xl`}></i>
						<b>Dashboard</b>
					</NavLink>
				)}
				<NavLink
					className={({ isActive }: { isActive: boolean }) => (isActive ? 'selected navLink' : 'navLink')}
					to="/orders"
				>
					<i className={`ri-shopping-cart-2-line ri-xl`}></i>
					<b>Orders</b>
				</NavLink>
			</div>
		);
	};
	return (
		<div className="privateNavigation">
			<div className="navLogo">
				<Link to="/home">
					MY BREEDERS STORE
					{/* <img className="navLogoImg" src={logoImg} alt="site logo" /> */}
				</Link>
			</div>
			{renderNavigationItems()}
			{getToken() !== '' && user && (
				<div className="navProfile">
					<Dropdown placement="bottomRight" arrow menu={{ items: profileItems }} trigger={['click']}>
						<span className="navProfileCta">
							<Avatar
								src={user.profilePic ?? require(`../../../assets/images/vectors/${randomVector}.png`)}
							/>
							{user.isBuyer ? (
								<Typography.Text>
									{user.firstName} {user.lastName} {user.name}
								</Typography.Text>
							) : (
								<Typography.Text>{user.registryName}</Typography.Text>
							)}
						</span>
					</Dropdown>
				</div>
			)}
			<div onClick={() => setOpenMobileMenu(true)} className="privateMobileNavItem">
				<i className="ri-menu-line ri-2x"></i>
			</div>
			<MobileMenuDrawer
				opened={openMobileMenu}
				onCancel={() => setOpenMobileMenu(false)}
				onForceCb={() => console.log('')}
			/>
		</div>
	);
};

export default PrivateNavbar;
