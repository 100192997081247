import React from 'react';
import './SettingsView.scss';
import { Avatar, Button, Card, Col, Form, Input, Row, Space, Typography, Upload, message } from 'antd';
import { randomVector } from '../../utils/randomVector';
import type { UploadProps } from 'antd';
import PageLoader from '../../components/PageLoader/PageLoader';
import { useGetUser } from '../../utils/useGetUser';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../../utils/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const SettingsView: React.FC = () => {
	const [form] = Form.useForm();
	const { user, isLoading, setUser }: any = useGetUser();

	if (!user || isLoading) return <PageLoader />;

	const onUpdateInformation = async (values: any) => {
		if (!user.uid || !user) return;
		try {
			const userRef = doc(db, user.isBuyer ? 'Users' : 'Breeders', user.uid);
			updateDoc(userRef, {
				...values
			});
			setUser({ ...user, ...values });
			message.success(`Successfully updated personal details.`);
		} catch (error) {
			message.error(`Something wen't wrong in updating your personal details.`);
		}
	};

	const onUpdateBreederInformation = async (values: any) => {
		if (!user.uid || !user) return;
		if (user.isBuyer) return;
		try {
			const userRef = doc(db, 'Breeders', user.uid);
			updateDoc(userRef, {
				...values
			});
			message.success(`Successfully updated breeder details.`);
		} catch (error) {
			message.error(`Something wen't wrong in updating your breeder details.`);
		}
	};

	const userUploadProps: UploadProps = {
		customRequest: async (e) => {
			const file: any = e.file;
			const onSuccess: any = e.onSuccess;
			const onError: any = e.onError;
			try {
				const storageRef = ref(storage, `coverPhoto/${user?.uid}/${new Date().getTime()}`);
				const task = uploadBytesResumable(storageRef, file);
				await task.then();
				const imageUrl = await getDownloadURL(task.snapshot.ref);
				const userRef = doc(db, user.isBuyer ? 'Users' : 'Breeders', user.uid);
				updateDoc(userRef, {
					profilePic: imageUrl,
					avatar: imageUrl
				});
				setUser({ ...user, profilePic: imageUrl, avatar: imageUrl });

				message.success(`${file.name} file uploaded successfully`);
				if (typeof onSuccess === 'function') onSuccess();
			} catch (error) {
				console.error(`${file.name} file upload failed:`, error);
				message.error(`${file.name} file upload failed.`);
				if (typeof onError === 'function') onError();
			}
		},
		accept: 'image/*',
		data: {
			field: 'profilePic',
			ref: 'plugin::users-permissions.user',
			refId: user && user.id
		}
	};

	const breederUploadProps: UploadProps = {
		data: {
			field: 'profilePic',
			ref: 'api::breeder.breeder',
			refId: user && user.breeder && user.breeder.id
		},
		accept: 'image/*',
		customRequest: async (e) => {
			const file: any = e.file;
			const onSuccess: any = e.onSuccess;
			const onError: any = e.onError;
			try {
				const storageRef = ref(storage, `coverPhoto/${user?.uid}/${new Date().getTime()}`);
				const task = uploadBytesResumable(storageRef, file);
				await task.then();
				const imageUrl = await getDownloadURL(task.snapshot.ref);
				const userRef = doc(db, 'Breeders', user.uid);
				updateDoc(userRef, {
					businessPic: imageUrl,
					avatar: imageUrl
				});
				setUser({ ...user, businessPic: imageUrl, avatar: imageUrl });
				message.success(`${file.name} file uploaded successfully`);
				if (typeof onSuccess === 'function') onSuccess();
			} catch (error) {
				console.error(`${file.name} file upload failed:`, error);
				message.error(`${file.name} file upload failed.`);
				if (typeof onError === 'function') onError();
			}
		}
	};
	return (
		<div className="settingsView">
			<Card className="settingsHeaderCard">
				<Row className="settingsHeader" gutter={[16, 16]} align={'middle'} justify={'space-between'}>
					<Col lg={20} className="settingsHeaderAvatar">
						<Avatar
							size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
							src={user?.profilePic ?? require(`../../assets/images/vectors/${randomVector}.png`)}
							shape="circle"
						/>
						<div className="settingsHeaderInfo">
							<Typography.Title level={4}>{`${user.firstName ? user.firstName : ''} ${
								user.lastName ? user.lastName : ''
							} ${user.username ? user.username : ''}`}</Typography.Title>
							<Typography.Paragraph>{user.isBuyer ? `User` : `Breeder`}</Typography.Paragraph>
						</div>
					</Col>
					<Col className="settingsHeaderCta" lg={4}>
						<Upload {...userUploadProps}>
							<Button>Select Image</Button>
						</Upload>
					</Col>
				</Row>
			</Card>
			<Card className="settingsPersonalInfoCard">
				<Typography.Title className="settingsPersonalInfoHeaderTxt" level={4}>
					{user.isBuyer ? 'User' : 'Breeder'} Personal Information
				</Typography.Title>
				<Form initialValues={user} size="large" layout={'horizontal'} onFinish={onUpdateInformation}>
					<Form.Item name="username" label="Username">
						<Input readOnly />
					</Form.Item>
					<Form.Item name={'firstName'} label="First name">
						<Input placeholder="First Name" />
					</Form.Item>
					<Form.Item name={'lastName'} label="Last name">
						<Input placeholder="Last Name" />
					</Form.Item>
					<Form.Item name="email" label="Email">
						<Input readOnly />
					</Form.Item>
					<Form.Item>
						<Button htmlType="submit" type="primary">
							Save Information
						</Button>
					</Form.Item>
				</Form>
			</Card>

			{!user.isBuyer && (
				<Card className="settingsBusinessInfoCard">
					<Typography.Title className="settingsBusinessInfoHeaderTxt" level={4}>
						Business Information
					</Typography.Title>
					<Form
						initialValues={user?.breeder ?? user}
						size="large"
						layout={'horizontal'}
						form={form}
						onFinish={onUpdateBreederInformation}
					>
						<Form.Item label="">
							<Space>
								<Avatar
									size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
									src={user.businessPic ?? require(`../../assets/images/vectors/${randomVector}.png`)}
									shape="circle"
								/>
								<Upload {...breederUploadProps}>
									<Button>Select Business Image</Button>
								</Upload>
							</Space>
						</Form.Item>
						<Form.Item name="businessName" label="Business Name">
							<Input placeholder="Enter business name" />
						</Form.Item>
						<Form.Item name="businessAddress" label="Business Location">
							<Input placeholder="Enter business location" />
						</Form.Item>
						<Form.Item name="aboutBusiness" label="About your kennel">
							<Input.TextArea rows={5} placeholder="Tell us about your kennel" />
						</Form.Item>
						<Form.Item>
							<Button htmlType="submit" type="primary">
								Update Business Information
							</Button>
						</Form.Item>
					</Form>
				</Card>
			)}
		</div>
	);
};

export default SettingsView;
