import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { getNormalDate } from './dateHelper';

export const useGetBreeder = (uid) => {
	const [isLoading, setIsLoading] = useState(false);
	const [breeder, setBreeder] = useState({});

	useEffect(() => {
		(async () => {
			try {
				if (!uid) return;
				setIsLoading(true);
				const breederRef = doc(db, 'Breeders', uid);
				const breeder = await getDoc(breederRef);
				if (breeder.exists()) {
					setBreeder({ ...breeder.data(), date: getNormalDate(breeder.data()?.date) });
				}
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			} finally {
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid]);

	return { breeder, isLoading };
};
