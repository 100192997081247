import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'G-FQBJWG3LKH');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
	  <>
		<Helmet>
		  <title>My Breeders Store - Your Trusted Partner in Finding the Perfect Dog</title>
		  <meta name="description" content="Explore our extensive directory of dog breeds and find your perfect companion today." />
		  <meta name="keywords" content="dog breeder, puppies, find dogs, dog breeds" />
		  <link rel="canonical" href="https://mybreedersstore.com" />
		  <link rel="icon" href={`${process.env.PUBLIC_URL}/mbs-logo.png`} type="image/png" />
		  {/* Social media image using the same logo */}
		  <meta property="og:image" content={`${process.env.PUBLIC_URL}/mbs-logo.png`} />
		  <meta property="og:description" content="Your Trusted Partner in Finding the Perfect Dog" />
		  <meta property="og:title" content="Dog Breeder" />
		</Helmet>
		<ConfigProvider
		  theme={{
			token: {
			  colorPrimary: '#97c66a',
			  colorLink: '#007aff',
			  fontFamily: 'Roboto, sans-serif'
			}
		  }}
		>
		  <Router>
			<App />
		  </Router>
		</ConfigProvider>
	  </>
	</React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
