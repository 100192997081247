import React, { useEffect, useRef, useState } from 'react';
import './Inboxpage.scss';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Avatar, Badge, Button, Col, ConfigProvider, Empty, Input, List, Result, Row, Typography, message } from 'antd';
import Moment from 'react-moment';
import { useLocalStorage } from 'usehooks-ts';
import { Model } from '../../models/model';
import { Link } from 'react-router-dom';
import MessageBlock from '../../components/MessageBlock/MessageBlock';
// import { randomVector } from '../../utils/randomVector';
import PageLoader from '../../components/PageLoader/PageLoader';
import { useGetUser } from '../../utils/useGetUser';
import { collection, doc, onSnapshot, query, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { Helmet } from 'react-helmet';

<head>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"></meta>
	</head>

const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
	let timeoutId: NodeJS.Timeout;
	return (...args: Parameters<F>) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

const Inboxpage: React.FC = () => {
	const { user, isLoading }: any = useGetUser();
	const [selectedConversationId, setSelectedConversationId]: any = useLocalStorage<number>(
		'selectedConversationId',
		0
	);
	const [selectedConversationMobileId, setSelectedConversationMobileId] = useLocalStorage<string>(
		'selectedConversationMobileId',
		'0'
	);
	const [selectedConversation, setSelectedConversation] = useState<Model.Conversation | null>(null);
	const [inboxData, setInboxData] = useState<Model.Conversation[]>([]);
	const [messageInput, setMessageInput] = useState<string>('');
	const [searchInboxTxt, setSearchInboxTxt] = useState<string>('');
	const dialogueContainer = useRef<HTMLDivElement>(null);
	const [currentViewportDate, setCurrentViewportDate] = useState<string | null>(null);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 576);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleViewportChange = () => {
		if (!dialogueContainer.current) return;

		const visibleMessages = dialogueContainer.current.querySelectorAll('.messageBlock');
		const visibleMessageBlocks = [];

		for (let i = 0; i < visibleMessages.length; i++) {
			const messageBlock = visibleMessages[i] as HTMLElement;
			const rect = messageBlock.getBoundingClientRect();

			// Check if the message block is within the viewport
			if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
				visibleMessageBlocks.push(messageBlock);
			}
		}

		if (visibleMessageBlocks.length > 0) {
			const lastVisibleMessageBlock = visibleMessageBlocks[visibleMessageBlocks.length - 1];
			const messageDate: any = lastVisibleMessageBlock.dataset.messageDate;
			setCurrentViewportDate(messageDate);
		} else {
			setCurrentViewportDate(null);
		}
	};

	const debouncedHandleViewportChange = debounce(handleViewportChange, 100); // Debounce scroll events
	const [messages, setMessages]: any = useState([]);
	useEffect(() => {
		(async () => {
			if (!user || !user.uid || !selectedConversation || !selectedConversation?.messageId) return;
			let unsubscribe: any;
			try {
				const messagesRef = collection(db, 'Messages', selectedConversation?.messageId, 'MyMessages');
				const myMessagesQuery = query(messagesRef);

				unsubscribe = onSnapshot(myMessagesQuery, (querySnapshot) => {
					try {
						if (querySnapshot.empty) return;
						const messageTmp = querySnapshot.docs.map((item) => ({ ...item.data(), id: item.id }));
						setMessages(
							messageTmp.sort((a: any, b: any) => ((a.sentDate?.seconds || 0) as number) - ((b.sentDate?.seconds || 0) as number)) // Sort messages by sent date, earliest first
						);											
					} catch (error) {
						message.error(`Something wen't wrong in getting conversations.`);
					}
				});
			} catch (error) {
				console.log(error);
				message.error(`Something wen't wrong in getting conversations.`);
			}
			return () => {
				if (typeof unsubscribe === 'function') unsubscribe();
			};
		})();
	}, [selectedConversation, user]);

	useEffect(() => {
		(async () => {
			if (!user || !user.uid) return;
			let unsubscribe: any;
			try {
				const ref = collection(db, 'Chats', user.uid, 'MyChats'); //2OgobkGusJM2k38bnaVScWVMGV82
				const myChatsQuery = query(ref);
				unsubscribe = onSnapshot(myChatsQuery, (querySnapshot) => {
					try {
						if (querySnapshot.empty) return;
						const promises = querySnapshot.docs.map(async (element: any) => {
							// const messagesRef = collection(db, 'Messages', element.data().messageId, 'MyMessages');
							// const myMessagesQuery = query(messagesRef);
							// const snapShot = await getDocs(myMessagesQuery);
							// const messageTmp = snapShot.docs.map((item) => ({ ...item.data(), id: item.id }));
							return { ...element.data(), id: element.id }; //messages: messageTmp
						});
						if (promises.length) {
							Promise.all(promises)
								.then((tmp) => {
									if (tmp) {
										setInboxData(
											tmp.sort((a: any, b: any) => (b.sentDate?.seconds || 0) - (a.sentDate?.seconds || 0)) // Sort conversations by the latest message time, latest first
										);										
										// if (tmp?.length && !selectedConversation && !selectedConversationId) {
										// 	setSelectedConversation(tmp[0]);
										// 	setSelectedConversationId(tmp[0].id);
										// }
									}
								})
								.catch((error) => {
									console.error('Error processing data:', error);
								});
						}
					} catch (error) {
						message.error(`Something wen't wrong in getting conversations.`);
					}
				});
			} catch (error) {
				console.log(error);
				message.error(`Something wen't wrong in getting conversations.`);
			}
			return () => {
				if (typeof unsubscribe === 'function') unsubscribe();
			};
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		scrollToBottom();
		if (selectedConversation && !selectedConversation?.isRead) {
			(async () => {
				try {
					if (typeof selectedConversation.id !== 'string') return;
					const ref = doc(db, 'Chats', user.uid, 'MyChats', selectedConversation.id);
					await updateDoc(ref, {
						isRead: true
					});
				} catch (error) {
					console.log(error);
				}
			})();
		}
	}, [selectedConversation, user]);

	const renderInboxHeader = () => {
		if (!selectedConversation || !user) return;
		return (
			<div className="inboxConversationHeader">
				<Typography.Title className="inboxConversationHeaderTxt">
					{selectedConversation.senderName}
				</Typography.Title>
				{user.isBuyer && <Typography.Paragraph>{selectedConversation.registryName}</Typography.Paragraph>}
				<div className="conversationStatusContainer">
					<Badge
						dot
						className="conversationStatusColor"
						status={selectedConversation.id ? 'success' : 'default'}
					/>
					<Typography.Text className="conversationStatusTxt">
						{selectedConversation.id ? `Active Now` : 'Offline'}
					</Typography.Text>
				</div>
			</div>
		);
	};

	const renderInboxFooter = () => {
		if (!selectedConversation) return;
		return (
			<div className="inboxConversationCta">
				<Input.TextArea
					className="inboxConversationInput"
					bordered={false}
					style={{ resize: 'none' }}
					placeholder="Write a message..."
					rows={5}
					value={messageInput}
					onChange={(e: any) => setMessageInput(e.target.value)}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							onSendMessage();
						}
					}}
				/>
				<Button
					className="inboxConversationSend"
					type="primary"
					icon={<i className="ri-send-plane-2-line"></i>}
					onClick={onSendMessage}
				>
					Send message
				</Button>
			</div>
		);
	};

	const renderEmptyConversation = () => {
		if (selectedConversation || !user) return;
		return (
			<Result
				status="info"
				title="Empty"
				subTitle="You haven't started any conversations or you havent selected a conversation from below"
				extra={
					<Link to="/home">
						<Button type="primary">Find {user.isBuyer ? `Pups` : 'Users'}</Button>
					</Link>
				}
			/>
		);
	};

	const scrollToBottom = () => {
		if (dialogueContainer.current) {
			dialogueContainer.current.scrollTop = dialogueContainer.current.scrollHeight;
		}
	};

	const onChangeConversation = (conversation: any) => {
		setSelectedConversationId(conversation.id);
		setSelectedConversationMobileId(conversation.id);
		setSelectedConversation(conversation);
		setInboxData((prevInboxData) =>
			prevInboxData
				.map((conv: any) => (conv.id === conversation.id ? { ...conv, sentDate: serverTimestamp() } : conv))
				.sort((a: any, b: any) => (b.sentDate?.seconds || 0) - (a.sentDate?.seconds || 0)) // Sort conversations by last received message time, latest first
		);
		
	};
	const onSendMessage = async () => {
		if (!selectedConversation || !user) return;
		if (!messageInput) {
			message.error(`You can't send a blank message`);
			return;
		}
		try {
			if (typeof selectedConversation.id !== 'string') return;
	
			// Define references for the Firebase collections and documents.
			const chatCollection = collection(db, 'Chats', user.uid, 'MyChats');
			const chatRef = doc(chatCollection, selectedConversation.id);
	
			const destChatCollection = collection(db, 'Chats', selectedConversation.id, 'MyChats');
			const destChatRef = doc(destChatCollection, user.uid);
	
			const messegeCollection = collection(db, 'Messages', selectedConversation.messageId, 'MyMessages');
			const newMessageRef = doc(messegeCollection);
	
			// Perform the send operation in a Promise.all block.
			await Promise.all([
				setDoc(newMessageRef, {
					messageText: messageInput,
					senderId: user.uid,
					senderName: user.isBuyer
						? user.name
							? user.name
							: `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: user.businessName
						? user.businessName
						: user.registryName
						? user.registryName
						: user.firstName || user.lastName
						? `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: '',
					senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
					sentDate: serverTimestamp()
				}),
				updateDoc(chatRef, {
					isRead: false,
					lastMessage: messageInput,
					sentDate: serverTimestamp()
				}),
				updateDoc(destChatRef, {
					isRead: false,
					lastMessage: messageInput,
					sentDate: serverTimestamp()
				})
			]);
	
			// Clear message input only if sending is successful.
			setMessageInput('');
		} catch (error) {
			console.error(error); // Use console.error for better logging.
			message.error(`Something went wrong in sending a message.`);
		}
	};
	

	const filterSearchInbox = (text: string, searchText: string) => {
		if (!text) return;
		return text.toLowerCase().includes(searchText.toLowerCase());
	};

	const renderInbox = () => {
		if (!user) return;
		let inboxDataList = inboxData;

		if (searchInboxTxt) {
			if (user.isBuyer) {
				const filteredInboxData = inboxData?.filter(({ receiver }) => {
					const { businessName, registryName, prefix } = receiver?.breeder;
					return (
						filterSearchInbox(businessName, searchInboxTxt) ||
						filterSearchInbox(registryName, searchInboxTxt) ||
						filterSearchInbox(prefix, searchInboxTxt)
					);
				});
				inboxDataList = filteredInboxData;
			} else {
				const filteredInboxData = inboxData.filter(({ sender }) => {
					const { email, firstName, lastName } = sender;
					return (
						filterSearchInbox(email, searchInboxTxt) ||
						filterSearchInbox(firstName, searchInboxTxt) ||
						filterSearchInbox(lastName, searchInboxTxt)
					);
				});
				inboxDataList = filteredInboxData;
			}
		}
		return (
			<ConfigProvider renderEmpty={() => <Empty description="No messages" />}>
				<div className="inboxSearchContainer">
					<Input
						value={searchInboxTxt}
						onChange={(e: any) => setSearchInboxTxt(e.target.value)}
						size="large"
						className="inboxSearch"
						placeholder="Search messages"
						prefix={<i className="ri-search-line"></i>}
					/>
				</div>
				<List
					itemLayout="horizontal"
					dataSource={inboxDataList}
					renderItem={(item: any, index) => (
						<List.Item
							onClick={() => onChangeConversation(item)}
							className={`inboxListItem ${item.id === selectedConversationId ? `selected` : ''}`}
						>
							<List.Item.Meta
								avatar={
									<Avatar
										size={64}
										src={
											item.senderImage?.length && item.senderImage?.length > 1
												? item.senderImage
												: require(`../../assets/images/vectors/user-dp.png`)
										}
									/>
								}
								title={
									<span className="inboxItemTitleContainer">
										<span className="inboxItemTitleName">{item.senderName}</span>
										<span className="inboxItemTitleDate">
											<Moment fromNow ago>
												{new Date(item.sentDate?.seconds * 1000)}
											</Moment>
										</span>
									</span>
								}
								description={item.lastMessage}
							/>
						</List.Item>
					)}
				/>
			</ConfigProvider>
		);
	};

	const renderSelectedConversation = () => {
		if (!selectedConversationId || !selectedConversation || !user) return null;
		if (!Array.isArray(messages) || !messages.length) return null;

		return messages
			.sort((a, b) => a.sentDate?.seconds - b.sentDate?.seconds)
			.map((message) => (
				<MessageBlock
					key={message.id}
					right={message.senderId === user.uid}
					message={message}
					senderImage={selectedConversation.senderImage}
				/>
			));
	};

	useEffect(() => {
		const currentContainer = dialogueContainer.current;
		if (currentContainer) {
			currentContainer.addEventListener('scroll', debouncedHandleViewportChange);
		}

		return () => {
			if (currentContainer) {
				currentContainer.removeEventListener('scroll', debouncedHandleViewportChange);
			}
		};
	}, [selectedConversationId, selectedConversation, debouncedHandleViewportChange]);

	const renderSelectedViewDate = () => {
		if (!currentViewportDate) return null;

		const currentDate = new Date();
		const viewportDate = new Date(currentViewportDate);

		if (
			currentDate.getFullYear() === viewportDate.getFullYear() &&
			currentDate.getMonth() === viewportDate.getMonth() &&
			currentDate.getDate() === viewportDate.getDate()
		) {
			return <div className="selectedViewingDate">Today</div>;
		}

		return (
			<div className="selectedViewingDate">
				<Moment format="MMMM DD, YYYY" fromNow>
					{currentViewportDate}
				</Moment>
			</div>
		);
	};

	if (!user || isLoading) return <PageLoader />;

	return (
		<PrivateLayout className="inboxPage customLayoutWidth">
			<PageTitle title="Inbox" />
			{selectedConversationMobileId ? (
				<a href="/inbox" className="messageBackBtn" onClick={() => setSelectedConversationMobileId('0')}>
					<i className="ri-arrow-left-s-line"></i>
					Back
				</a>
			) : (
				<></>
			)}
			{isSmallScreen ? (
				<Row className="inboxContent">
					{!selectedConversation?.id ? (
						<Col
							xs={selectedConversationMobileId ? 0 : 24}
							sm={selectedConversationMobileId ? 0 : 24}
							md={9}
							className="inboxList"
							lg={6}
							xl={6}
							xxl={6}
						>
							{renderInbox()}
						</Col>
					) : (
						<Col
							xs={selectedConversationMobileId ? 24 : 0}
							sm={selectedConversationMobileId ? 24 : 0}
							md={15}
							lg={18}
							xl={18}
							xxl={18}
						>
							<div className="inboxConversation">
								{renderInboxHeader()}
								<div
									onScroll={handleViewportChange}
									ref={dialogueContainer}
									className={`inboxConversationContent ${
										selectedConversation ? 'conversationContent' : 'emptyConversation'
									}`}
								>
									{renderSelectedViewDate()}
									{renderSelectedConversation()}
									{renderEmptyConversation()}
								</div>
								{renderInboxFooter()}
							</div>
						</Col>
					)}
				</Row>
			) : (
				<Row className="inboxContent">
					<Col
						xs={selectedConversationMobileId ? 0 : 24}
						sm={selectedConversationMobileId ? 0 : 24}
						md={9}
						className="inboxList"
						lg={6}
						xl={6}
						xxl={6}
					>
						{renderInbox()}
					</Col>
					<Col
						xs={selectedConversationMobileId ? 24 : 0}
						sm={selectedConversationMobileId ? 24 : 0}
						md={15}
						lg={18}
						xl={18}
						xxl={18}
					>
						<div className="inboxConversation">
							{renderInboxHeader()}
							<div
								onScroll={handleViewportChange}
								ref={dialogueContainer}
								className={`inboxConversationContent ${
									selectedConversation ? 'conversationContent' : 'emptyConversation'
								}`}
							>
								{renderSelectedViewDate()}
								{renderSelectedConversation()}
								{renderEmptyConversation()}
							</div>
							{renderInboxFooter()}
						</div>
					</Col>
				</Row>
			)}
		</PrivateLayout>
	);
};

export default Inboxpage;
