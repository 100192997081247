import React, { useEffect } from 'react';
import './SuccessPage.scss';
import { Button, Result, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { useGetUser } from '../../utils/useGetUser';
import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';

const SuccessPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramCheckoutSession: string | null = queryParams.get('checkout_session');
  const { user }: any = useGetUser();

  useEffect(() => {
    if (paramCheckoutSession && user && user.uid) {
      (async () => {
        try {
          // First, retrieve the session details from your backend
          const response = await fetch(`/api/stripe/get-session?session_id=${paramCheckoutSession}`);
          const sessionData = await response.json();

          if (!response.ok) {
            throw new Error('Failed to retrieve session data');
          }

          // Calculate dates
          const now = new Date();
          const subscriptionDate = now;
          const expiryDate = new Date(now);
          expiryDate.setFullYear(now.getFullYear() + 1); // 1 year from now

          // Create the subscription object using Stripe session data
          const breederRef = doc(db, 'Breeders', user.uid);
          await updateDoc(breederRef, {
            isSubscribed: true,
            subscription: {
              dateOfExpiry: expiryDate.toISOString(),
              dateSubscribed: subscriptionDate.toISOString(),
              lastRenewedAt: subscriptionDate.toISOString(),
              // These values would come from your Stripe session
              originalTransactionId: sessionData.originalTransactionId,
              productId: "com.appetiser.mybreedersstore.annual", // This stays constant
              subscriptionId: sessionData.subscriptionId,
              transactionId: sessionData.transactionId
            }
          });

          message.success('Successfully subscribed!');
        } catch (error) {
          console.error('Subscription error:', error);
          message.error('Something went wrong while processing your subscription');
        }
      })();
    }
  }, [paramCheckoutSession, user]);

  return (
    <div className="customStripeSuccessOnboardingPage absolute-centered">
      <Result
        className="customOnboardingResult"
        status="success"
        title="Successfully Subscribed!"
        subTitle={
          <span>
            Your breeder subscription is now active. If you have any questions, please reach out to{' '}
            <a href="mailto:hello@mybreedersstore.com">hello@mybreedersstore.com</a>
          </span>
        }
        extra={[
          <Button onClick={() => window.close()} type="primary" key="console">
            Return to My Breeders Store
          </Button>
        ]}
      />
    </div>
  );
};

export default SuccessPage;