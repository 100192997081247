import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentForm = ({ orderId }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [loaidng, setLoading] = useState(false);
	const location = useLocation();

	const onSubmit = async (e) => {
		try {
			e.preventDefault();
			setLoading(true);
			if (!stripe || !elements) return null;

			const result = await stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: window.location.origin + location.pathname + location.search + `&orderId=${orderId}`
				}
			});
			if (result.error) {
				console.log(result.error);
			} else {
				// Your customer will be redirected to your `return_url`.
			}
		} catch (error) {
			console.log({ error });
		} finally {
			setLoading(false);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<PaymentElement className="w-full" />
			<div className="checkoutCta" style={{ marginTop: '1rem' }}>
				<Button loading={loaidng} disabled={loaidng} htmlType="submit" type="primary">
					checkout
				</Button>
			</div>
		</form>
	);
};

export default PaymentForm;
