import React from 'react';
import './MessageBlock.scss';
import { Model } from '../../models/model';
import { Avatar, Typography } from 'antd';
import Moment from 'react-moment';
import { randomVector } from '../../utils/randomVector';

interface MessageBlockProps {
	message: Model.Message;
	right?: boolean;
	senderImage?: string;
}
const MessageBlock: React.FC<MessageBlockProps> = ({ message, right, senderImage }) => {
	return (
		<div
			data-message-date={new Date(message.sentDate?.seconds * 1000)}
			className={`messageBlock ${right ? 'rightAlign' : ''}`}
		>
			<Avatar
				src={senderImage ? senderImage : require(`../../assets/images/vectors/${randomVector}.png`)}
				size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64 }}
			/>
			<div className="messageBlockMeta">
				<div className="messageBlockMetaInfo">
					{message.senderName && (
						<b className="messageBlockUser">
							{message.senderName} {right ? '(You)' : ''}
						</b>
					)}
				</div>
				<div className="messageBlockMsg">
					<Typography.Paragraph className="messageBlockTxt">{message.messageText}</Typography.Paragraph>
				</div>
				<small className="messageBlockDate">
					<Moment date={new Date(message.sentDate?.seconds * 1000)} format="hh:mm A" />
				</small>
			</div>
		</div>
	);
};

export default MessageBlock;
