import { useEffect, useState } from 'react';
import { getToken } from './authHelpers';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setCurrentUser } from '../store/auth/authSlice';
import { getNormalDate } from './dateHelper';

export const useSubUser = () => {
	const uid = getToken();
	const user = useSelector(selectUser) ?? {};
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const setUser = (payload) => dispatch(setCurrentUser(payload));
	useEffect(() => {
		let unsubscribe;
		(async () => {
			try {
				if (!uid) return;
				if (!user.uid) setIsLoading(true);
				const breederRef = doc(db, 'Breeders', uid);
				const breeder = await getDoc(breederRef);
				if (breeder.exists()) {
					setUser(breeder.data());
					unsubscribe = onSnapshot(breederRef, (snapshot) => {
						const data = snapshot.data();
						setUser({
							...data,
							date: getNormalDate(data?.date),
							onboardedAt: getNormalDate(data?.onboardedAt),
							verifiedActionDate: getNormalDate(data?.verifiedActionDate),
							subscription: {
								...data.subscription,
								dateOfExpiry: getNormalDate(data.subscription?.dateOfExpiry),
								dateSubscribed: getNormalDate(data.subscription?.dateSubscribed),
								lastRenewedAt: getNormalDate(data.subscription?.lastRenewedAt)
							}
						});
					});
				} else {
					const userRef = doc(db, 'Users', uid);
					const user = await getDoc(userRef);
					if (user.exists()) {
						setUser(user.data());
						unsubscribe = onSnapshot(userRef, (snapshot) => {
							setUser({ ...snapshot.data(), date: getNormalDate(snapshot.data()?.date) });
						});
					}
				}
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			} finally {
				setIsLoading(false);
			}
		})();
		return () => {
			if (typeof unsubscribe === 'function') unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { user, isLoading, setUser };
};
