import React from 'react';
import './NotificationView.scss';
import { Card, List, message } from 'antd';
import { Model } from '../../models/model';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';

interface NotificationViewProps {
	listOfNotifications: Model.Notification[];
	setListOfNotifications: (value: any) => void;
	setCount: (count: number) => void;
	onForceCb: () => void;
}

const NotificationView: React.FC<NotificationViewProps> = ({ listOfNotifications }) => {
	const onRead = async (notification: Model.Notification) => {
		if (!notification || typeof notification.id !== 'string') return;
		try {
			const notificationRef = doc(db, 'Notifications', notification.id);
			await updateDoc(notificationRef, {
				isRead: true
			});
			message.success(`Notification marked as read.`);
		} catch (error) {
			message.error(`Something wen't wrong in marking notification as read.`);
		}
	};

	return (
		<Card className="notificationCard">
			<List
				itemLayout="horizontal"
				dataSource={listOfNotifications}
				renderItem={(item, index) => (
					<List.Item
						actions={!item.isRead ? [<i onClick={() => onRead(item)} className="ri-eye-line"></i>] : []}
						className={`notificationItem ${item.isRead ? 'read' : ''}`}
					>
						<List.Item.Meta
							avatar={<i className="ri-notification-3-line"></i>}
							title={item.type}
							description={item.message}
						/>
					</List.Item>
				)}
			/>
		</Card>
	);
};

export default NotificationView;
