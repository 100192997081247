import React, { useEffect, useState } from 'react';
import './PaywallPopup.scss';
import { PopupModel } from '../../models/PopupModel';
import { Button, Modal, Typography, message } from 'antd';
import registerImg from '../../assets/images/register.png';
import { removeToken } from '../../utils/authHelpers';
import { useGetUser } from '../../utils/useGetUser';
import { SUBSCRIPTION_CHECKOUT_URL } from '../../utils/constant';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../utils/firebase';

interface PaywallPopupProps extends PopupModel {}

const { confirm } = Modal;

const PaywallPopup: React.FC<PaywallPopupProps> = ({ opened, onCancel, onForceCb }) => {
  const { user, setUser }: any = useGetUser();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);
  const [, setIsTabActive] = useState(true);

  const isRecentSubscription = (dateSubscribed: string | number | Date) => {
    const subscriptionDate = new Date(dateSubscribed);
    const now = new Date();
    const subscriptionMinutes = Math.floor(subscriptionDate.getTime() / 1000 / 60);
    const currentMinutes = Math.floor(now.getTime() / 1000 / 60);
    return subscriptionMinutes === currentMinutes;
  };

  const onCheckout = () => {
    if (!user) return;
    setIsProcessing(true);
    let url = process.env.REACT_APP_STRIPE_SUBSCRIPTION_CHECKOUT_URL
      ? process.env.REACT_APP_STRIPE_SUBSCRIPTION_CHECKOUT_URL
      : SUBSCRIPTION_CHECKOUT_URL;
    window.open(`${url}?prefilled_email=${user.email}`, '_blank');
  };

  const onCancelPayment = () => {
    if (!user) return;
    confirm({
      title: `Not interested in signing up?`,
      content: `Did you know you can try it for 30 days before you are charged. `,
      centered: true,
      okText: 'Exit',
      cancelText: 'Back to payment',
      onOk() {
        onCancel();
        removeToken("paywall");
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!user || paymentCompleted) return;
    
    let unsubscribe: any;
    try {
      const breederRef = doc(db, 'Breeders', user?.uid);
      unsubscribe = onSnapshot(breederRef, (snapshot) => {
        const userAuth = snapshot.data();
        if (userAuth?.isSubscribed && !paymentCompleted) {
          setPaymentCompleted(true);
          setIsProcessing(false);
          setUser({ ...user, ...userAuth });
          onForceCb();
          
          // Only show success message if the subscription just happened
          if (userAuth.dateSubscribed && isRecentSubscription(userAuth.dateSubscribed)) {
            message.success(`Successfully verified subscription payment.`);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }

    return () => {
      if (typeof unsubscribe === 'function') unsubscribe();
    };
  }, [user, opened, paymentCompleted]);

  return (
    <Modal
      closable={false}
      className="paywallPopup"
      open={opened}
      onCancel={isProcessing ? undefined : onCancelPayment}
      footer={null}
      centered
      title={null}
    >
      <div className="paywallPopupContent">
        <img src={registerImg} alt="register wall asset" className="paywallImg" />
        <Typography.Title className="paywallTitleTxt" level={3}>
          Breeder Subscriptions
        </Typography.Title>
        <Typography.Paragraph className="paywallSubTxt">
          Explore breeder's privileges & market your products. Browse other breeder's collections.
        </Typography.Paragraph>
        <Button
          className="paywallCta"
          disabled={isProcessing}
          loading={isProcessing}
          onClick={onCheckout}
          type="primary"
        >
          {!isProcessing ? `Proceed to checkout` : `Processing payment`}
        </Button>
        
        {isProcessing && (
          <Typography.Paragraph className="paywallFooterHelp">
            Pop-up still open? Close and <a href="#" onClick={() => window.location.reload()}>refresh the page</a>.
          </Typography.Paragraph>
        )}
        {!isProcessing && (
          <Typography.Paragraph className="paywallFooterHelp">
            Already have an active subscription? <a href="mailto:help@mybreedersstore.com">Need Help</a>
          </Typography.Paragraph>
        )}
      </div>
    </Modal>
  );
};

export default PaywallPopup;