import React, { useEffect, useState } from 'react';
import './Favoritespage.scss';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Col, Empty, Row, message } from 'antd';
import BreederCard from '../../components/BreederCard/BreederCard';
import { Api } from '../../models/api';
import PageLoader from '../../components/PageLoader/PageLoader';
import { useGetUser } from '../../utils/useGetUser';
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../../utils/firebase';

const Favoritespage: React.FC = () => {
	const { user, isLoading }: any = useGetUser();
	const [favorites, setFavorites] = useState<Api.Favorite.Res.FavoriteListing[]>([]);
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);

	const loadFavorites = async () => {
		if (!user) return;
		try {
			// eslint-disable-next-line
			const favCollection = collection(db, 'Users', user.uid, 'Favorites');
			const q = query(favCollection);
			const snapShot = await getDocs(q);
			const promises = snapShot.docs.map(async (element: any) => {
				const favData = element.data();
				const dogRef = doc(db, favData?.category ? favData.category : 'pupProfile', favData?.id);
				const dogSnapShot = await getDoc(dogRef);

				const dogData = dogSnapShot.data();
				return {
					animal: dogSnapShot.data(),
					id: dogSnapShot?.id,
					category: favData.category,
					image:
						Array.isArray(dogData?.dogImages) && dogData?.dogImages.length
							? dogData?.dogImages[0]
							: dogData?.dogImage
							? dogData?.dogImage
							: Array.isArray(dogData?.pupsImages) && dogData?.pupsImages.length
							? dogData?.pupsImages[0]
							: Array.isArray(dogData?.pupImages) && dogData?.pupImages.length
							? dogData?.pupImages[0]
							: ''
				};
			});
			if (promises.length) {
				Promise.all(promises).then((tmp: any) => {
					if (tmp) {
						setFavorites(tmp);
					}
				});
			}
		} catch (error) {
			console.log(error);
			message.error(`Something wen't wrong in getting list of favorites.`);
		}
	};

	useEffect(() => {
		loadFavorites();
		// eslint-disable-next-line
	}, [user, forceUpdate]);

	const renderFavoritesListing = () => {
		if (!Array.isArray(favorites) || !favorites.length) return <Empty />;
		return favorites.map((data: any) => (
			<Col key={data?.id} xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
				<BreederCard
					id={data.id}
					name={data.animal?.name}
					previewUrl={
						Array.isArray(data.animal?.profilePhotoURLs) && data.animal?.profilePhotoURLs?.length
							? data.animal?.profilePhotoURLs[0]
							: data.image
					}
					favorite
					favoriteId={data?.id}
					onForceCb={() => setForceUpdate(!forceUpdate)}
					ownerId={data.animal?.uid}
					ownerName={data.animal?.breeder?.businessName}
					ownerProfileImageUrl={data.animal?.breeder?.avatar ? data.animal?.breeder?.avatar?.url : ''}
					pricing={data.animal?.price}
					category={data.category ?? data.animal?.category}
				/>
			</Col>
		));
	};

	if (!user || isLoading) return <PageLoader />;

	return (
		<PrivateLayout className="favoritesPage customLayoutWidth">
			<PageTitle title="My Favourites" />
			<div className="favoritesContent">
				<Row
					className="breederStoreFilterCategory"
					justify={!Array.isArray(favorites) || !favorites.length ? 'center' : 'start'}
					gutter={[24, 24]}
				>
					{renderFavoritesListing()}
				</Row>
			</div>
		</PrivateLayout>
	);
};

export default Favoritespage;
