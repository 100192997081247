import React from 'react';
import './Homepage.scss';
import { Typography } from 'antd';
import PublicLayout from '../../layouts/public/PublicLayout';
import { getToken } from '../../utils/authHelpers';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import BreederStorefront from '../../components/BreederShopfront/BreederStorefront';
import ProtectedRoute from '../../utils/ProtectedRoute';

const Homepage: React.FC = () => {
	const loggedIn: boolean = getToken() !== '';

	const renderLandingPage = () => {
		const neutralStoreFront = (
			<>
				<div className="homepageBannerHero">
					<div className="homepageBannerImgContainer">
						<img
							src="https://images.pexels.com/photos/7210727/pexels-photo-7210727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
							className="homepageBannerImg"
							alt="hero banner"
						/>
					</div>
					{loggedIn ? <Typography.Title className="homepageBannerTitle" level={1}>
					<img src={`${process.env.PUBLIC_URL}/mbs-logo.png`} alt="MBS Logo" style={{ maxWidth: '300px', width: '100%', height: 'auto' }} />
					</Typography.Title> : null}
				</div>
				<BreederStorefront />
			</>
		);
		if (loggedIn) {
			return <ProtectedRoute><PrivateLayout className="homepage">{neutralStoreFront}</PrivateLayout></ProtectedRoute>;
		} else {
			return (
				<PublicLayout navbar className="homepage">
					{neutralStoreFront}
				</PublicLayout>
			);
		}
	};

	return renderLandingPage();
};

export default Homepage;
