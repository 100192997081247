import React, { useState } from 'react';
import './Registerpage.scss';
import PublicLayout from '../../layouts/public/PublicLayout';
import { Avatar, Col, Row, Space, Typography, Upload, message } from 'antd';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import breederAccountType from '../../assets/images/breeder.png';
import buyerAccountType from '../../assets/images/buyer.png';
import AccountTypeSelection from '../../components/AccountTypeSelection/AccountTypeSelection';
import { Api } from '../../models/api';
import { removeToken } from '../../utils/authHelpers';
import registerImg from '../../assets/images/mainHero.png';
import { Steps } from 'antd';
import type { UploadProps } from 'antd';
import { Model } from '../../models/model';
// import Tesseract from 'tesseract.js';
import { auth, db, storage } from '../../utils/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

interface ParsedData {
	firstName: string;
	lastName: string;
	name: string;
	cardNumber: string;
	prefix: string;
	registryName: string;
}

const Registerpage: React.FC = () => {
	const navigate = useNavigate();
	const [registrationStep, setRegistrationStep] = useState<number>(0);
	const [accountType, setAccountType] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [avatarImageLogo, setAvatarImageLogo] = useState<Model.Image>({
		url: '',
		id: 0
	});
	const [avatarImageCover, setAvatarImageCover] = useState<Model.Image>({
		url: '',
		id: 0
	});
	const [user, setUser] = useState<Api.User.Res.LoggedInUser>();
	const [breederData, setBreederData] = useState({
		businessName: ''
	});
	const [parsedData, setParsedData] = useState<ParsedData>({
		firstName: '',
		lastName: '',
		name: '',
		cardNumber: '',
		prefix: '',
		registryName: ''
	});
	const [breederCardPhoto, setBreederCardPhoto] = useState<any>();

	const onRegister = async (values: any) => {
		if (!values) return;
		try {
			setIsLoading(true);
			let registerData: Api.User.Req.Create = {
				isBuyer: accountType === 1 ? true : false,
				firstName: values.firstName,
				lastName: values.lastName,
				username: values.username.split('@')[0],
				email: values.username,
				password: values.password
			};
			if (accountType === 0) {
				let currentBusinessName: string = '';
				registerData.registryName = values.registryName;
				registerData.prefix = values.prefix;
				registerData.phone = values.phone;
				currentBusinessName = values.registryName;
				if (parsedData) {
					if (parsedData.firstName) {
						registerData.firstName = parsedData.firstName;
					}
					if (parsedData.lastName) {
						registerData.lastName = parsedData.lastName;
					}
					if (parsedData.prefix) {
						registerData.prefix = parsedData.prefix;
					}
					if (parsedData.registryName) {
						currentBusinessName = parsedData.registryName;
					}
				}
				setBreederData({
					businessName: currentBusinessName
				});
			}

			const res = await createUserWithEmailAndPassword(auth, registerData.email, registerData.password);
			await updateProfile(res.user, {
				displayName: parsedData.firstName
					? `${registerData.firstName} ${registerData.lastName}`
					: registerData.registryName
				// phoneNumber: registerData.phone
			});
			const user = auth.currentUser;

			if (user) {
				let userRef;
				if (registerData.isBuyer) {
					userRef = doc(db, 'Users', user.uid);
					await setDoc(userRef, {
						name: `${registerData.firstName} ${registerData.lastName}`,
						date: new Date(),
						uid: user.uid,
						email: registerData.email,
						isBuyer: registerData.isBuyer,
						prefix: registerData.prefix ?? '',
						phone: registerData.phone ?? '',
						registryName: registerData.registryName ?? '',
						username: registerData.username ?? '',
						lastName: registerData.lastName ?? '',
						firstName: registerData.firstName ?? ''
					});
				} else {
					userRef = doc(db, 'Breeders', user.uid);
					await setDoc(userRef, {
						date: new Date(),
						uid: user.uid,
						email: registerData.email,
						isBuyer: registerData.isBuyer,
						prefix: registerData.prefix ?? '',
						phone: registerData.phone ?? '',
						registryName: registerData.registryName ?? '',
						username: registerData.username ?? '',
						lastName: registerData.lastName ?? '',
						firstName: registerData.firstName ?? ''
					});
				}
				message.success('Successfully created an account.');
				const createdUser: any = await getDoc(userRef);
				setIsLoading(false);
				if (createdUser.data().isBuyer) {
					navigate('/home');
				} else {
					setUser(createdUser.data());
					proceedNextStep();
				}
			}
		} catch (err: any) {
			setIsLoading(false);
			console.log(err);
			if (err.response?.data) {
				const { error } = err.response?.data;
				if (error.message) {
					message.error(error.message);
				} else {
					message.error(`Something wen't wrong in creating an account. `);
				}
			} else {
				if (err.message) {
					message.error(err.message);
				} else {
					message.error(`Something wen't wrong in creating an account. `);
				}
			}
		}
	};

	const proceedNextStep = () => {
		setRegistrationStep(registrationStep + 1);

		    // Only validate images for breeder accounts
    if (accountType === 0 && registrationStep === 1) {
        if (!avatarImageCover.url) {
            message.error("Please upload a cover photo before proceeding.");
            return;
        }
        if (!avatarImageLogo.url) {
            message.error("Please upload a profile logo before proceeding.");
            return;
        }
        if (!breederCardPhoto) {
            message.error("Please upload the breeder card before proceeding.");
            return;
        }
    }
    setRegistrationStep(registrationStep + 1);
};

	const renderFirstRegistrationStep = () => {
		if (registrationStep !== 0) return;
		return (
			<Space className="accountTypeSelectionParentContainer" direction="vertical">
				<div className="accountTypesSelectionContainer">
					<AccountTypeSelection
						activeSetup={accountType}
						currentSetup={1}
						label={'User'}
						imgSrc={buyerAccountType}
						onClick={(setup: number) => setAccountType(setup)}
					/>
					<AccountTypeSelection
						activeSetup={accountType}
						currentSetup={0}
						label={'Breeder'}
						imgSrc={breederAccountType}
						onClick={(setup: number) => setAccountType(setup)}
					/>
				</div>
				<Button type="dashed" onClick={proceedNextStep}>
					Next
				</Button>
			</Space>
		);
	};

	const buyerSteps = [
		{
			title: 'Step 1'
		},
		{
			title: 'Step 2'
		}
	];

	const breederSteps = [
		{
			title: 'Step 1'
		},
		{
			title: 'Step 2'
		},
		{
			title: 'Step 3'
		}
	];

	const onUpdateBreeder = async (values: any) => {
		if (!values || !user) return;
		// if (!user.breeder) return;

 // Validate that cover photo, logo, and breeder card are uploaded
 if (!avatarImageCover.url) {
	message.error("Please upload a cover photo before proceeding.");
	return;
}

if (!avatarImageLogo.url) {
	message.error("Please upload a profile logo before proceeding.");
	return;
}

if (!breederCardPhoto) {
	message.error("Please upload the breeder card before proceeding.");
	return;
}

// Proceed with the update logic
try {
	setIsLoading(true);
	let updateData = {
		data: {
			...values,
			onboarding_registration: false
		}
	};
	const storageRef = ref(
		storage,
		`BreederIdentityProof/${user?.username}/${breederCardPhoto}${new Date().getTime()}`
	);
	const task = uploadBytesResumable(storageRef, breederCardPhoto);
	await task.then();
	const cardUrl = await getDownloadURL(task.snapshot.ref);

	const breederRef = doc(db, 'Breeders', user.uid);
	await updateDoc(breederRef, {
		...updateData.data,
		coverPic: avatarImageCover.url,
		businessPic: avatarImageLogo.url,
		cardPhoto: cardUrl,
		proofId: cardUrl
	});

	setIsLoading(false);
	message.success(`Successfully updated breeder details.`);
	navigate(`/pending-approval`);
} catch (error) {
	setIsLoading(false);
	console.log(error);
	message.error(`Something went wrong in onboarding a breeder account.`);
} // Validate that cover photo, logo, and breeder card are uploaded
if (!avatarImageCover.url) {
	message.error("Please upload a cover photo before proceeding.");
	return;
}

if (!avatarImageLogo.url) {
	message.error("Please upload a profile logo before proceeding.");
	return;
}

if (!breederCardPhoto) {
	message.error("Please upload the breeder card before proceeding.");
	return;
}

// Proceed with the update logic
try {
	setIsLoading(true);
	let updateData = {
		data: {
			...values,
			onboarding_registration: false
		}
	};
	const storageRef = ref(
		storage,
		`BreederIdentityProof/${user?.username}/${breederCardPhoto}${new Date().getTime()}`
	);
	const task = uploadBytesResumable(storageRef, breederCardPhoto);
	await task.then();
	const cardUrl = await getDownloadURL(task.snapshot.ref);

	const breederRef = doc(db, 'Breeders', user.uid);
	await updateDoc(breederRef, {
		...updateData.data,
		coverPic: avatarImageCover.url,
		businessPic: avatarImageLogo.url,
		cardPhoto: cardUrl,
		proofId: cardUrl
	});

	setIsLoading(false);
	message.success(`Successfully updated breeder details.`);
	navigate(`/pending-approval`);
} catch (error) {
	setIsLoading(false);
	console.log(error);
	message.error(`Something went wrong in onboarding a breeder account.`);
};
		
		setIsLoading(true);
		try {
			let updateData = {
				data: {
					...values,
					onboarding_registration: false
				}
			};
			const storageRef = ref(
				storage,
				`BreederIdentityProof/${user?.username}/${breederCardPhoto}${new Date().getTime()}`
			);
			const task = uploadBytesResumable(storageRef, breederCardPhoto);
			await task.then();
			const cardUrl = await getDownloadURL(task.snapshot.ref);

			const breederRef = doc(db, 'Breeders', user.uid);
			await updateDoc(breederRef, {
				...updateData.data,
				coverPic: avatarImageCover.url,
				businessPic: avatarImageLogo.url,
				cardPhoto: cardUrl,
				proofId: cardUrl
			});

			setIsLoading(false);
			message.success(`Successfully updated breeder details.`);
			removeToken("register page");
			navigate(`/pending-approval`);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			message.error(`Something wen't wrong in onboarding a breeder account.`);
		}
	};

	const breederUploadPropsCoverPhoto: UploadProps = {
		customRequest: async (e) => {
			const file: any = e.file;
			const onSuccess: any = e.onSuccess;
			const onError: any = e.onError;
			try {
				const storageRef = ref(storage, `coverPhoto/${user?.username}/${file}${new Date().getTime()}`);
				const task = uploadBytesResumable(storageRef, file);
				await task.then();

				// Get the download URL for the uploaded image
				const imageUrl = await getDownloadURL(task.snapshot.ref);

				// Update the UI or state with the uploaded image URL
				const ImageRes: Model.Image = {
					id: file.uid, // Use a unique ID for the image
					url: imageUrl
				};
				setAvatarImageCover(ImageRes);
				message.success(`${file.name} file uploaded successfully`);
				if (typeof onSuccess === 'function') onSuccess();
			} catch (error) {
				console.error(`${file.name} file upload failed:`, error);
				message.error(`${file.name} file upload failed.`);
				if (typeof onError === 'function') onError();
			}
		},
		accept: 'image/*',
		data: {
			field: 'coverPhoto',
			ref: 'api::breeder.breeder',
			refId: user && user.breeder && user.breeder.id
		}
	};

	const breederUploadPropsLogo: UploadProps = {
		customRequest: async (e) => {
			const file: any = e.file;
			const onSuccess: any = e.onSuccess;
			const onError: any = e.onError;
			try {
				const storageRef = ref(storage, `avatar/${user?.username}/${file}${new Date().getTime()}`);
				const task = uploadBytesResumable(storageRef, file);
				await task.then();

				// Get the download URL for the uploaded image
				const imageUrl = await getDownloadURL(task.snapshot.ref);

				// Update the UI or state with the uploaded image URL
				const ImageRes: Model.Image = {
					id: file.uid, // Use a unique ID for the image
					url: imageUrl
				};
				setAvatarImageLogo(ImageRes);
				message.success(`${file.name} file uploaded successfully`);
				if (typeof onSuccess === 'function') onSuccess();
			} catch (error) {
				console.error(`${file.name} file upload failed:`, error);
				message.error(`${file.name} file upload failed.`);
				if (typeof onError === 'function') onError();
			}
		},
		accept: 'image/*',
		data: {
			field: 'avatar',
			ref: 'api::breeder.breeder',
			refId: user && user.breeder && user.breeder.id
		}
	};

	// const parseTextToJSON = async (text: string): Promise<ParsedData> => {
	// 	// Define the position marker for card number
	// 	const cardNumberMarker = 'Member Number:';
	// 	const cardNumberIndex = text.indexOf(cardNumberMarker);

	// 	if (cardNumberIndex !== -1) {
	// 		// Extract the text before the card number marker
	// 		const textBeforeCardNumber = text.substring(0, cardNumberIndex);

	// 		// Split the text before card number marker into lines
	// 		const lines = textBeforeCardNumber.split('\n');

	// 		// Find the last non-empty line, which likely contains the name
	// 		let name = '';
	// 		for (let i = lines.length - 1; i >= 0; i--) {
	// 			const line = lines[i].trim();
	// 			if (line.length > 0) {
	// 				name = line;
	// 				break;
	// 			}
	// 		}

	// 		// Extract the card number and kennel prefix based on their marker positions
	// 		const cardNumberMatch = text.match(/Member Number: (.+)/);
	// 		const prefixMatch = text.match(/Kennel Prefix: (.+)/);

	// 		const cardNumber = cardNumberMatch ? cardNumberMatch[1].trim() : '';
	// 		const prefix = prefixMatch ? prefixMatch[1].trim() : '';
	// 		const cleanedName = name.replace(/(^|\s)(.)(?=\s|$)/g, '');

	// 		const parts = cleanedName.split(/\s+/);

	// 		let firstName = '';
	// 		let lastName = '';

	// 		if (parts.length > 0) {
	// 			firstName = parts[0];
	// 			lastName = parts.slice(1).join(' ');
	// 		}

	// 		return { name: cleanedName, cardNumber, prefix, firstName, lastName, registryName: prefix };
	// 	} else {
	// 		// Card number marker not found
	// 		return { name: '', cardNumber: '', prefix: '', firstName: '', lastName: '', registryName: '' };
	// 	}
	// };

	const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setIsLoading(true);
			const imageFile = event.target.files?.[0];

			if (!imageFile) return;

			// console.log(JSON.stringify(imageFile));

			// Process the image using Tesseract.js
			// const { data } = await Tesseract.recognize(imageFile);
			// const extractedText = data.text;
			// Parse extracted text and generate JSON
			// console.log('extracted ocr text', extractedText);
			// const parsedJson = await parseTextToJSON(extractedText);

			// if (!parsedJson.name || !parsedJson.prefix) {
			// 	message.error('Breeder card is invalid.');
			// 	setIsLoading(false);
			// 	return;
			// }
			// setParsedData({ ...parsedData, ...parsedJson });
			setBreederCardPhoto(imageFile);
			message.success('Successfully uploaded breeder card.');
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		} finally {
			setIsLoading(false);
		}
	};

	const handleInputChange = (e: any) => setParsedData({ ...parsedData, [e.target.name]: e.target.value });

	const { prefix, registryName } = parsedData;

	return (
		<PublicLayout navbar className="registerPage">
			<Row gutter={[32, 32]} className="registerPageRow" align={'middle'}>
				<Col className="registerHeroCol" xs={0} sm={0} md={10} lg={10} xl={10} xxl={10}>
					<img src={registerImg} alt="register hero" className="registerHero" />
				</Col>
				<Col className="registerRightCol" xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
					<div className={`registerCard ${registrationStep === 0 ? 'fixedHeight' : ''}`}>
						<Steps
							responsive={false}
							className="registerStepper"
							size="small"
							current={registrationStep}
							items={
								registrationStep !== 0 ? (accountType === 1 ? buyerSteps : breederSteps) : buyerSteps
							}
						/>
						{registrationStep === 0 && (
							<Typography.Title className="registerHeaderTitle" level={2}>
								Create your account
							</Typography.Title>
						)}
						{renderFirstRegistrationStep()}
						{registrationStep !== 0 && (
							<b className="registrationFormHeader">
								{accountType === 1 ? 'User' : 'Breeder'} Registration
							</b>
						)}
						{registrationStep === 1 && (
							<>
								<Form
									name="normal_login"
									className="registerForm"
									initialValues={parsedData}
									onFinish={onRegister}
									size="large"
								>
									{accountType === 1 && (
										<>
											<Form.Item
												name="firstName"
												rules={[{ required: true, message: 'Please enter first name.' }]}
											>
												<Input placeholder="First Name" />
											</Form.Item>
											<Form.Item
												name="lastName"
												rules={[{ required: true, message: 'Please enter last name.' }]}
											>
												<Input placeholder="Last Name" />
											</Form.Item>
										</>
									)}

									<Form.Item
										name="username"
										rules={[{ required: true, message: 'Please enter your Username!' }]}
									>
										<Input type="email" placeholder="Emaill Address" />
									</Form.Item>

									<Form.Item
										name="password"
										rules={[
											{ required: true, message: 'Please enter your Password!' },
											{ min: 6, message: 'Password must be at least 6 characters.' }
										]}
									>
										<Input.Password type="password" placeholder="Password" />
									</Form.Item>

									{accountType === 0 && (
										<>
											<Form.Item name="phone">
												<Input placeholder="Phone" />
											</Form.Item>
											<Form.Item
												name="registryName"
												rules={[
													{ required: true, message: 'Please input your Registry name!' }
												]}
											>
												<Input
													value={registryName}
													name="registryName"
													onChange={handleInputChange}
													placeholder="Enter Registry Name"
												/>
											</Form.Item>

											<Form.Item name="prefix">
												<Input
													value={prefix}
													name="prefix"
													onChange={handleInputChange}
													placeholder="Enter Prefix"
												/>
												<small className="prefixOptionalExtra">Prefix is optional</small>
											</Form.Item>

											<Form.Item>
												<label
													className={`custom-breeder-card-upload ${
														isLoading ? 'disabled' : ''
													}`}
												>
													<input
														type="file"
														accept="image/*"
														disabled={isLoading}
														onChange={handleImageUpload}
													/>
													{breederCardPhoto ? (
														<img
															style={{ maxWidth: '100%' }}
															src={URL.createObjectURL(breederCardPhoto)}
															alt="breeder card"
															className="breederCoverPhotoImg"
														/>
													) : (
														<>
															<i className="ri-upload-cloud-2-line ri-lg"></i>Upload
															breeder card
														</>
													)}
												</label>
											</Form.Item>
										</>
									)}

									<Form.Item>
										<Button
											disabled={isLoading}
											loading={isLoading}
											type="primary"
											htmlType="submit"
											className="registerCta"
										>
											Sign Up
										</Button>
									</Form.Item>
								</Form>
							</>
						)}
						{registrationStep === 2 && (
							<Form
								name="normal_login"
								layout="vertical"
								className="registerForm"
								initialValues={{ remember: true, ...breederData }}
								onFinish={onUpdateBreeder}
								size="large"
							>
								<Form.Item className="breederPhotoInputContainer" label="">
									<Space>
										<Upload {...breederUploadPropsCoverPhoto}>
											<Button className={`breederCoverPhoto ${avatarImageCover ? 'hasImg' : ''}`}>
												{avatarImageCover && avatarImageCover.url ? (
													<img
														className="breederCoverPhotoImg"
														src={avatarImageCover.url || registerImg}
														alt="breeder cover"
													/>
												) : (
													<i className="ri-image-line ri-5x"></i>
												)}
											</Button>
										</Upload>
										<Upload {...breederUploadPropsLogo}>
											<Avatar
												size={75}
												shape="circle"
												className="clickable"
												icon={<i className="ri-image-line"></i>}
												src={avatarImageLogo ? avatarImageLogo.url : null}
											/>
										</Upload>
									</Space>
								</Form.Item>
								<Form.Item
									name="businessName"
									rules={[{ required: true, message: 'Please input your Business name!' }]}
								>
									<Input placeholder="Business name" />
								</Form.Item>
								<Form.Item
									name="businessAddress"
									rules={[{ required: true, message: 'Please input your Business location!' }]}
								>
									<Input placeholder="Business location" />
								</Form.Item>
								<Form.Item name="aboutBusiness" label="About your kennel">
									<Input.TextArea rows={5} placeholder="Tell us about your kennel" />
								</Form.Item>
								<Form.Item>
									<Button
										disabled={isLoading}
										loading={isLoading}
										type="primary"
										htmlType="submit"
										className="registerCta"
									>
										Submit
									</Button>
								</Form.Item>
							</Form>
						)}
					</div>
					<Typography.Paragraph className="registerSubTxt">
					User registration is free, breeders pay $129 yearly with one month free to all new subscribers
					</Typography.Paragraph>
				</Col>
			</Row>
		</PublicLayout>
	);
};

export default Registerpage;