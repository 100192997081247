import React, { useEffect, useState } from 'react';
import './Orderspage.scss';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Button, Col, Dropdown, Image, Modal, Row, Typography, message } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import FormatMoney from '../../utils/FormatMoney';
import type { MenuProps } from 'antd';
import ViewOrderDrawer from '../../drawers/ViewOrder/ViewOrderDrawer';
import { Model } from '../../models/model';
import statusColor from '../../utils/statusColors';
import { randomVector } from '../../utils/randomVector';
import { Api } from '../../models/api';
import statusText from '../../utils/statusText';
import { and, collection, doc, getDocs, or, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useGetUser } from '../../utils/useGetUser';

const { confirm } = Modal;

const Orderspage: React.FC = () => {
	const { user }: any = useGetUser();
	const [orderStatus, setOrderStatus] = useState<string>('All');
	const [openViewOrder, setOpenViewOrder] = useState<boolean>(false);
	const [orderListing, setOrderListing] = useState<Model.Order[]>([]);
	const [selectedOrder, setSelectedOrder] = useState<Api.Order.Res.OrderListing | null>(null);
	const [forceUpdate, setForceUpdate] = useState<boolean>(false);

	const onChangeOrderStatus = (status: string) => setOrderStatus(status);

	const onViewOrder = () => {
		setOpenViewOrder(true);
	};

	const onReceivedOrder = async () => {
		if (!selectedOrder) return;
		confirm({
			title: 'Order Received',
			content: 'Are you sure to mark this order as received?',
			centered: true,
			okText: 'Yes, i received it',
			async onOk() {
				try {
					const orderRef = doc(db, 'Orders', selectedOrder.orderId);
					await updateDoc(orderRef, {
						isMarkedAsReceivedByBuyer: true,
						orderStatus: 'Received',
						status: 'Delivered',
						isReceivedByUser: true,
						isDeliveredByBreeder: true
					});
					setForceUpdate(!forceUpdate);
					message.success(`Order marked as received.`);
				} catch (error) {
					console.log(error);
					message.error(`Something wen't wrong in marking order as received.`);
				}
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	const onCancelOrder = async () => {
		if (!selectedOrder) return;
		confirm({
			title: 'Cancel Order',
			content: 'Are you sure you want to cancel this order?',
			centered: true,
			async onOk() {
				try {
					const orderRef = doc(db, 'Orders', selectedOrder.orderId);
					await updateDoc(orderRef, {
						status: 'Cancelled',
						paymentStatus: 'Refunded',
						orderStatus: 'Cancelled'
					});

					setForceUpdate(!forceUpdate);
					message.success(`Order successfully Cancelled and will refund user.`);
				} catch (error) {
					message.error(`Something wen't wrong in cancelling order.`);
				}
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	const onMarkSent = async () => {
		if (!selectedOrder) return;
		confirm({
			title: 'Dispatch Order',
			content: 'Is the ordered product on its way?',
			centered: true,
			async onOk() {
				try {
					const orderRef = doc(db, 'Orders', selectedOrder.orderId);
					await updateDoc(orderRef, {
						status: 'IN_TRANSIT'
					});
					setForceUpdate(!forceUpdate);
					message.success(`Order successfully marked sent & its on the way.`);
				} catch (error) {
					message.error(`Something wen't wrong in marking the order in transit.`);
				}
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	const columns: ColumnsType<Model.Order> = [
		{
			title: 'Name',
			dataIndex: '',
			key: 'name',
			render: ({ animal = {}, breeder = {}, ordered_by = {}, breederID, itemId, name, images, pupImages }) => {
				return (
					<div className="orderTitleContent">
						<Image
							src={
								Array.isArray(images) && images.length
									? images[0]
									: Array.isArray(pupImages) && pupImages.length
									? pupImages[0]
									: require(`../../assets/images/vectors/${randomVector}.png`)
							}
						/>
						<div className="orderTitleBreederContent">
							<Typography.Text className="orderTitleTxt">
								{animal.name ?? animal.pupName ?? name}
							</Typography.Text>
							<Typography.Text className="orderTitleOwnerTxt">
								{!user?.isBuyer ? ordered_by.email : breeder.businessName}
							</Typography.Text>
						</div>
					</div>
				);
			}
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'age',
			render: (price) => {
				return <Typography.Text className="orderPricing">{FormatMoney(price)}</Typography.Text>;
			}
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'orderStatus',
			render: (status) => (
				<Tag className="orderStatusTag" color={statusColor(status)} key={`id-${status}`}>
					{statusText(status)}
				</Tag>
			)
		},
		{
			title: 'Address',
			dataIndex: '',
			key: 'address',
			render: ({ addressCity, addressLine1 }) => (
				<Typography.Text className="orderPricing">{`${addressCity} ${addressLine1 ?? ''}`}</Typography.Text>
			)
		},
		{
			title: 'Description',
			dataIndex: '',
			key: 'itemDescription',
			render: ({ itemDescription, bio }) => (
				<Typography.Text className="orderPricing">{`${itemDescription ?? ''} ${bio ?? ''}`}</Typography.Text>
			)
		},
		{
			title: 'Payment Status',
			key: 'paymentStatus',
			dataIndex: 'paymentStatus',
			render: (status) => (
				<Tag className="orderStatusTag" color={statusColor(status)} key={`id-${status}`}>
					{status.toLowerCase()}
				</Tag>
			)
		},
		{
			title: '',
			dataIndex: '',
			key: 'action',
			render: (record) => {
				if (!user) return;
				let items: MenuProps['items'] = [
					{
						key: '1',
						label: (
							<Typography.Text className="orderTableColCta">
								<i className="ri-eye-line"></i> View
							</Typography.Text>
						),
						onClick: () => onViewOrder()
					},
					{
						key: '2',
						label: (
							<Typography.Text className="orderTableColCta">
								<i className="ri-award-line"></i> Received
							</Typography.Text>
						),
						disabled: record.status === 'Delivered' || record.isReceivedByUser ? true : false,
						className: 'receivedCta',
						onClick: () => onReceivedOrder()
					},
					{
						key: '3',
						label: (
							<Typography.Text className="orderTableColCta">
								<i className="ri-checkbox-circle-line"></i> Mark sent
							</Typography.Text>
						),
						disabled: record.status === 'IN_TRANSIT' ? true : false,
						className: 'receivedCta',
						title: 'Stripe functionality should be finished in order for this to work',
						onClick: () => onMarkSent()
					},
					{
						key: '4',
						label: (
							<Typography.Text
								className="orderTableColCta"
								title="Cannot cancel order if order is in transit."
							>
								<i className="ri-close-circle-line"></i> Cancel
							</Typography.Text>
						),
						disabled: record.status === 'IN_TRANSIT' || record.status === 'Delivered' ? true : false,
						className: 'deleteCta',
						title: 'Stripe functionality should be finished in order for this to work',
						onClick: () => onCancelOrder()
					}
				];
				if (user.isBuyer) {
					items = items.slice(0, -2);
				} else {
					items = items.filter((item: any) => item.key !== '2');
				}
				return (
					<>
						<Dropdown
							overlayClassName="ordersTableColCtaDropdown"
							trigger={['click']}
							menu={{ items }}
							onOpenChange={() => setSelectedOrder(record)}
							placement="bottom"
							arrow={{ pointAtCenter: true }}
						>
							<Button size="small">
								<i className="ri-more-2-line"></i>
							</Button>
						</Dropdown>
					</>
				);
			}
		}
	];

	const loadListOfOrders = async () => {
		if (!user || !user.uid || !orderStatus) return;
		try {
			const orderRef = collection(db, 'Orders');
			let q = query(
				orderRef,
				and(
					or(where('buyerId', '==', user.uid), where('breederId', '==', user.uid)),
					where('orderStatus', '==', orderStatus)
				)
			);
			if (orderStatus === 'All') {
				q = query(orderRef, or(where('buyerId', '==', user.uid), where('breederId', '==', user.uid)));
			} else if (orderStatus === 'Delivered') {
				q = query(
					orderRef,
					and(
						or(where('buyerId', '==', user.uid), where('breederId', '==', user.uid)),
						where('orderStatus', 'array-contains', ['Delivered', orderStatus])
					)
				);
			}
			const data = await getDocs(q);
			const tmp: any[] = [];
			data.forEach(async (element) => {
				const order: any = element.data();
				let breeder: any = {};
				let animal: any = {};
				const normalOrder = {
					animal,
					breeder,
					...order,
					...order.address,
					orderId: element.id,
					addressLine1: order.address?.line1
				};
				tmp.push(normalOrder);
			});
			console.log(
				tmp.sort(
					(a, b) => new Date(b.dateCreated?.seconds).getTime() - new Date(a.dateCreated?.seconds).getTime()
				)
			);
			setOrderListing(
				tmp.sort(
					(a, b) => new Date(b.dateCreated?.seconds).getTime() - new Date(a.dateCreated?.seconds).getTime()
				)
			);
		} catch (error) {
			console.log(error);
			message.error(`Something wen't wrong in getting list of orders.`);
		}
	};

	useEffect(() => {
		loadListOfOrders();
		// eslint-disable-next-line
	}, [orderStatus, user, forceUpdate]);

	return (
		<PrivateLayout className="ordersPage customLayoutWidth">
			<PageTitle title="Orders" />
			<Row className="ordersFilterContainer" gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
					<ButtonGroup>
						<Button
							onClick={() => onChangeOrderStatus('All')}
							type={orderStatus === 'All' ? 'primary' : 'default'}
						>
							All
						</Button>
						<Button
							onClick={() => onChangeOrderStatus('Pending')}
							type={orderStatus === 'Pending' ? 'primary' : 'default'}
						>
							Pending
						</Button>
						<Button
							onClick={() => onChangeOrderStatus('IN_TRANSIT')}
							type={orderStatus === 'IN_TRANSIT' ? 'primary' : 'default'}
						>
							In Transit
						</Button>
						<Button
							onClick={() => onChangeOrderStatus('Delivered')}
							type={orderStatus === 'Delivered' ? 'primary' : 'default'}
						>
							Delivered
						</Button>
						<Button
							onClick={() => onChangeOrderStatus('Cancelled')}
							type={orderStatus === 'Cancelled' ? 'primary' : 'default'}
						>
							Cancelled
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<div className="ordersTableContainer">
				<Table columns={columns} dataSource={orderListing} />
				{selectedOrder && (
					<ViewOrderDrawer
						opened={openViewOrder}
						order={selectedOrder}
						onCancel={() => setOpenViewOrder(false)}
						onForceCb={() => console.log('object')}
					/>
				)}
			</div>
		</PrivateLayout>
	);
};

export default Orderspage;
