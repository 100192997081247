import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    currentUser: null,
    loading: false,
    error: null,
    verification: null,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateUser: (state, action) => {
      state.currentUser = { ...state.currentUser, ...action.payload };
    },
    setAuthLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  setError,
  setAuthLoading,
  setCurrentUser,
  clearCurrentUser,
  updateUser,
} = authSlice.actions;

export const selectUser = (state) => state.authSlice.currentUser;
export const selectIsLoading = (state) => state.authSlice.loading;
export const selectError = (state) => state.authSlice.error;

export default authSlice.reducer;
