import React from 'react';
import './Landing.scss';
import { Col, Row } from 'antd';
import PublicLayout from '../../layouts/public/PublicLayout';
import { getToken } from '../../utils/authHelpers';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Link } from 'react-router-dom';
import landingImage from '../../assets/images/landing.png';
import landing_bottom from '../../assets/images/landing_bottom.png';
import appImage from '../../assets/images/appImage.png'; // Image to be centered under the heading

const Landing: React.FC = () => {
    const loggedIn: boolean = getToken() !== '';

    const renderLandingPage = () => {
        const neutralStoreFront = (
            <>
                {/* Hero Section with 30vh height */}
                <Row justify="center" gutter={[24, 24]} style={{ height: '30vh', display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={12} style={{ padding: '0', textAlign: 'center' }}>
                        <div className="heading-section">
                            <div className="heading-text">Find Your Perfect Pedigree Pup Today!</div>
                        </div>
                    </Col>
                </Row>

                {/* Main Section with vertically centered content */}
                <Row justify="center" gutter={[24, 24]} style={{ padding: '60px 0', display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                        <div className="content-container">
                            <div className="section-title">Connecting Dog Lovers</div>
                            <div className="section-text">
                                My breeders store is a platform for dog lovers to find their perfect furry companion. 
                                The app features a wide selection of pedigree dogs, with detailed information on each pup's breed, age, and temperament. 
                                <br/><br/> 
                                Users can easily browse listings, connect with breeders, and find their dream dog. 
                                Breeders can post their litters, chat live directly with users, and share listings on their social accounts.
                                <br></br>
                                Create a free user account to view listings
                            </div>
                            <Link to={'/register'} className="sign-up-button" style={{ display: 'inline-block', textAlign: 'center', padding: '15px 30px' }}>Sign up</Link>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} style={{ padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img className="landing-image" src={appImage} alt="landing image" style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '0 auto' }} />
                    </Col>
                </Row>

                                {/* New Row for Image Centered Below Heading */}
                                <Row justify="center" gutter={[24, 24]} style={{ marginTop: '20px' }}>
                    <Col xs={24} sm={24} md={12} style={{ padding: '0', textAlign: 'center' }}>
                        <img className="app-image" src={landingImage} alt="app screenshot" style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '0 auto' }} />
                    </Col>
                </Row>

{/* Secure Payment Section with dynamically reversed content on mobile */}
<Row justify="center" gutter={[24, 24]} className="secure-payment-row" style={{ padding: '60px 0', display: 'flex', alignItems: 'center' }}>
    <Col xs={24} sm={24} md={12} style={{ padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img className="landing-image" src={landing_bottom} alt="img" style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '0 auto' }} />
    </Col>
    <Col xs={24} sm={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
        <div className="content-container">
            <div className="section-title">Secure Payments, Peace of Mind</div>
            <div className="section-text">
                With My Breeders Store, breeders can create profiles for their dogs, post listings for dogs they are selling, and connect with dog lovers. 
                My Breeders Store uses Stripe for secure payments, ensuring all transactions are safe and protected.
            </div>
            <Link to={'/register'} className="sign-up-button" style={{ display: 'inline-block', textAlign: 'center', padding: '15px 30px' }}>Sign up</Link>
        </div>
    </Col>
</Row>

            </>
        );

        if (loggedIn) {
            return (
                <ProtectedRoute>
                    <PrivateLayout className="homepage">{neutralStoreFront}</PrivateLayout>
                </ProtectedRoute>
            );
        } else {
            return (
                <PublicLayout navbar className="homepage">
                    {neutralStoreFront}
                </PublicLayout>
            );
        }
    };

    return renderLandingPage();
};

export default Landing;
