import React, { useEffect, useState } from 'react';
import { DrawerModel } from '../../models/DrawerModel';
import './ViewOrderDrawer.scss';
import { Descriptions, Drawer, Tag, Typography, message } from 'antd';
import { Api } from '../../models/api';
import BreederCard from '../../components/BreederCard/BreederCard';
import AnimalBreederInfo from '../../components/AnimalBreederInfo/AnimalBreederInfo';
import { randomVector } from '../../utils/randomVector';
import statusColor from '../../utils/statusColors';
import statusText from '../../utils/statusText';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { categories } from '../../utils/constant';
import { useGetUser } from '../../utils/useGetUser';

interface ViewOrderDrawerProps extends DrawerModel {
	order: Api.Order.Res.OrderListing | null;
}

const ViewOrderDrawer: React.FC<ViewOrderDrawerProps> = ({ opened, onCancel, order }) => {
	const { user }: any = useGetUser();
	const [breeder, setBreeder]: any = useState({});
	const [animal, setAnimal]: any = useState({});
	useEffect(() => {
		(async () => {
			try {
				if (order?.breederId && typeof order.breederId === 'string') {
					const breederRef = doc(db, 'Breeders', order.breederId);
					const snapShot = await getDoc(breederRef);
					const breederData: any = snapShot.data();
					setBreeder(breederData);
				}
				if (order?.itemId && typeof order.itemId === 'string') {
					const animalRefs = categories.map((item) => doc(db, item.collection, order.itemId));
					for (const ref of animalRefs) {
						const animal: any = await getDoc(ref);
						if (animal.exists()) {
							const imageList =
								Array.isArray(animal.dogImages) && animal.dogImages.length
									? animal.dogImages
									: animal.dogImage
									? [animal.dogImage]
									: Array.isArray(animal.pupsImages) && animal.pupsImages.length
									? animal.pupsImages
									: Array.isArray(animal.pupImages) && animal.pupImages.length
									? animal.pupImages
									: '';
							const animalData = animal.data();
							setAnimal({ ...animalData, images: imageList });
							return; // Exit the loop if the document is found in any collection
						}
					}
				}
			} catch (error) {
				message.error('something went wronge in getting animal data ');
			}
		})();
	}, [order]);

	const renderAnimalDetails = () => {
		if (!order || !animal) return;
		return (
			<BreederCard
				id={animal.id}
				name={animal.name ?? animal.pupName}
				previewUrl={Array.isArray(animal.images) && animal.images.length ? animal.images[0] : ''}
				ownerId={breeder.id}
				ownerName={breeder.businessName}
				ownerProfileImageUrl={breeder.avatar ? breeder.avatar : ''}
				pricing={animal.dogPrice}
			/>
		);
	};

	const renderBuyerDetails = () => {
		if (!user) return;
		if (!order || !breeder) return;
		return (
			<div>
				<Typography.Title level={5}>{user.isBuyer ? `Order Details` : null}</Typography.Title>
				{user.isBuyer ? (
					<AnimalBreederInfo
						breederId={breeder.uid}
						name={breeder.businessName}
						avatarUrl={breeder.avatar ?? require(`../../assets/images/vectors/${randomVector}.png`)}
						description={breeder.aboutBusiness}
						address={breeder.businessAddress ?? order.addressLine1}
						verified={breeder.isVerified}
					/>
				) : (
					<>
						<Descriptions layout="vertical" title="User Details">
							<Descriptions.Item label="User">
								{order.ordered_by?.firstName} {order.ordered_by?.lastName}
							</Descriptions.Item>
							<Descriptions.Item label="Email">{order.ordered_by?.email}</Descriptions.Item>
							<Descriptions.Item label="Received by user">
								{!order.isReceivedByUser ? 'No' : 'Yes'}
							</Descriptions.Item>
							<Descriptions.Item label="Status">
								<Tag className="viewOrderStatusTag" color={statusColor(order.status)}>
									{statusText(order.status)}
								</Tag>
							</Descriptions.Item>
							<Descriptions.Item label="Address">{order.addressLine1}</Descriptions.Item>
						</Descriptions>

						<Descriptions layout="vertical" title="Additional Address Information">
							<Descriptions.Item label="Unit #">{order.addressUnitNumber || 'N/A'}</Descriptions.Item>
							<Descriptions.Item label="State">{order.addressState || 'N/A'}</Descriptions.Item>
							<Descriptions.Item label="City">{order.addressCity || 'N/A'}</Descriptions.Item>
							<Descriptions.Item label="Postal">{order.addressPostalCode || 'N/A'}</Descriptions.Item>
						</Descriptions>
					</>
				)}
			</div>
		);
	};

	return (
		<Drawer width={512} title="Order Details" className="viewOrderDrawer" open={opened} onClose={onCancel}>
			{renderAnimalDetails()}
			{renderBuyerDetails()}
		</Drawer>
	);
};

export default ViewOrderDrawer;
