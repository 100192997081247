import React, { useEffect, useState } from 'react';
import { PopupModel } from '../../models/PopupModel';
import './EditProductPopup.scss';
import { Button, Form, Input, InputNumber, Modal, Select, Space, Typography, message } from 'antd';
import { categories } from '../../utils/constant';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { Api } from '../../models/api';
import { db, storage } from '../../utils/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';

interface EditProductPopupProps extends PopupModel {
	animal: Api.Animal.Res.AnimalListing;
	categoryId: number;
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

const EditProductPopup: React.FC<EditProductPopupProps> = ({ opened, onCancel, onForceCb, animal, categoryId }) => {
	const [previewOpen, setPreviewOpen] = useState<boolean>(false);
	const [previewImage, setPreviewImage] = useState<string>('');
	const [previewTitle, setPreviewTitle] = useState<string>('');
	const [fileList, setFileList] = useState<any[]>([]);
	const [profilePhotoURLs, setprofilePhotoURLs] = useState<any[]>([]);
	const [ageType, setageType] = useState<string>("");
	

	function removeUndefinedProperties(obj: any) {
		for (let prop in obj) {
			if (obj[prop] === undefined) {
				delete obj[prop];
			}
		}
	}
	const onUpdate = async (values: any) => {
		try {
			const selectedCategoryId = values.categories;
			const selectedCategory = categories.find((item) => item.id === selectedCategoryId);
			const selectedCollection: string = selectedCategory?.collection ?? '';
			// const id = animal.id; // Or however you can uniquely identify the listing

	
			if (!selectedCollection) {
				console.error('Selected collection is undefined or empty.');
				return;
			}
	
			const normalData = { ...values };
			removeUndefinedProperties(normalData);
	
			const docRef = doc(db, selectedCollection); // Assuming `id` is the unique identifier
			await updateDoc(docRef, {
				...normalData,
				pupImages: profilePhotoURLs.map((item) => item.url)
			});
			message.success(`Animal successfully updated.`);
			onCancel();
			onForceCb();
		} catch (error) {
			console.log(error);
			message.error(`Something went wrong in updating a product.`);
		}
	};
	

	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
	};

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		if (Array.isArray(newFileList) && newFileList.length) {
			if (newFileList[0].status === 'done') {
				// message.success('Message uploaded successfully.');
			}
		}
	};

	const handleRemoveImage = async (file: any) => {
		if (!Array.isArray(fileList) || !fileList.length) return;
		// let filteredImages = fileList.filter((data) => data.id !== file.id);
		let filteredImagesUrl = profilePhotoURLs.filter((data) => data.id !== file.id);
		// setFileListRemove(filteredImages);
		setprofilePhotoURLs(filteredImagesUrl);
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const closeWithReset = () => {
		setPreviewOpen(false);
		setPreviewImage('');
		setPreviewTitle('');
		setFileList([]);
		setprofilePhotoURLs([]);
	};

	useEffect(() => {
		const loadInitValues = () => {
			if (!animal) return;
			if (!Array.isArray(animal.profilePhotoURLs) || !animal.profilePhotoURLs.length) return;
			setFileList(animal.profilePhotoURLs);
			setprofilePhotoURLs(animal.profilePhotoURLs);
		};
		loadInitValues();
	}, [opened, animal]);

	return (
		<Modal
			className="editProductPopup"
			open={opened}
			onOk={onUpdate}
			afterClose={closeWithReset}
			okButtonProps={{ htmlType: 'submit' }}
			onCancel={onCancel}
			okText="Update product"
			centered
			closable={false}
			title={'Edit Product'}
			footer={null}
			destroyOnClose
		>
			<Form initialValues={{ ...animal, categories: categoryId }} className="editProductForm" onFinish={onUpdate}>
			<Form.Item name="dogType">
					<Input placeholder="Breed of Dog" />
				</Form.Item>
				<Form.Item name="categories">
					<Select
						placeholder="Type"
						options={[
							{ value: 3, label: 'Pup For Showcase', key: 'PupForShowCase' },
							{ value: 2, label: 'Pups For Sale', key: 'pupProfile' },
							{ value: 1, label: 'Stud Profile', key: 'studProfile' },
							{ value: 4, label: 'Upcoming Litters', key: 'UpcomingLitters' }
						]}
					/>
				</Form.Item>
				<Form.Item name="dogPrice">
					<InputNumber className="productPricing" precision={2} placeholder="Price" />
				</Form.Item>
				<Form.Item name="dogAge">
					<InputNumber precision={0} placeholder="Age" />
				</Form.Item>
				
    <Form.Item label="">
      <Select defaultValue="Weeks or Years" value={ageType} onChange={(value) => setageType(value)}>
        <Select.Option value="Weeks">Weeks</Select.Option>
        <Select.Option value="Years">Years</Select.Option>
      </Select>
    </Form.Item>
				<Form.Item name="microChipNumber">
					<Input placeholder="Microchip Number" />
				</Form.Item>
				<Form.Item name="sourcenumbe">
					<Input placeholder="Source Number (only if you're from VIC)" />
				</Form.Item>
				<Form.Item name="aboutDog">
					<Input.TextArea placeholder="Tell us about your listing" rows={5} />
				</Form.Item>
				<Form.Item name="profilePhotoURLs">
					<Typography.Paragraph>Images</Typography.Paragraph>
					<Upload
						name="files"
						accept="image/*"
						listType="picture-card"
						fileList={fileList}
						onPreview={handlePreview}
						onChange={handleChange}
						onRemove={handleRemoveImage}
						customRequest={async (e) => {
							const file: any = e.file;
							const onSuccess: any = e.onSuccess;
							const onError: any = e.onError;
							try {
								const storageRef = ref(storage, `pups/${file?.name}/${new Date().getTime()}`);
								const task = uploadBytesResumable(storageRef, file);
								await task.then();
								const profilePhotoURLs = await getDownloadURL(task.snapshot.ref);
								setprofilePhotoURLs((pre) => [...pre, { url: profilePhotoURLs, name: file.name }]);
								message.success(`${file.name} file uploaded successfully`);
								if (typeof onSuccess === 'function') onSuccess();
							} catch (error) {
								console.error(`${file.name} file upload failed:`, error);
								message.error(`${file.name} file upload failed.`);
								if (typeof onError === 'function') onError();
							}
						}}
					>
						{fileList.length >= 8 ? null : uploadButton}
					</Upload>
				</Form.Item>

				<Form.Item className="editProductCta">
					<Space className="editProductCtaSpace" align="center">
						<Button htmlType="button" onClick={onCancel}>
							Cancel
						</Button>
						<Button type="primary" htmlType="submit">
							Update Product
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</Modal>
	);
};

export default EditProductPopup;
