import React from 'react';
import './Contactpage.scss';
import PublicLayout from '../../layouts/public/PublicLayout';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const Contactpage: React.FC = () => {
    return (
        <PublicLayout className="contactPage" navbar>
            <div className="contactContent">
                <div className="textSection">
                    <Title level={2}>Contact Us</Title>
                    <Paragraph>
                        If you need any assistance with our app, have questions, or just want to say hi, we're here for you!
                    </Paragraph>
                    <Paragraph>
                        Feel free to drop us an email at <a href="mailto:hello@mybreedersstore.com">hello@mybreedersstore.com</a> anytime. We're eager to help and would love to hear from you, whether it's feedback, queries, or just a friendly hello!
                    </Paragraph>
                </div>
                <div className="imageSection">
                    <img src={`${process.env.PUBLIC_URL}/image-contact.png`} alt="Contact Us" />
                </div>
            </div>
        </PublicLayout>
    );
};

export default Contactpage;
