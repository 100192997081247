import React, { useState } from 'react';
import './ContactBreederPopup.scss';
import { Input, Modal, Typography, message } from 'antd';
import { PopupModel } from '../../models/PopupModel';
import AnimalBreederInfo from '../../components/AnimalBreederInfo/AnimalBreederInfo';
import { Model } from '../../models/model';
import { randomVector } from '../../utils/randomVector';
import { collection, doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { useGetUser } from '../../utils/useGetUser';
import { useGetBreeder } from '../../utils/useGetBreeder';

interface ContactBreederPopupProps extends PopupModel {
	breeder: Model.Breeder | null;
}

const ContactBreederPopup: React.FC<ContactBreederPopupProps> = ({ opened, onCancel, onForceCb, breeder }) => {
	const [messageInput, setMessageInput] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string>('');
	const { user }: any = useGetUser();
	const { isLoading, breeder: breederInfo }: any = useGetBreeder(breeder?.uid);
	const chatUserId = user.uid;

	const onSendMessage = async () => {
		if (!breeder) return;
		if (!messageInput) {
			setErrorMsg(`Please input your message.`);
			return;
		}
		try {
			if (!user) return;
			const collectionRef = collection(db, 'Chats', chatUserId, 'MyChats');
			const chatRef = doc(collectionRef, breeder.uid);
			const snapShot = await getDoc(chatRef);
			const destChatCollection = collection(db, 'Chats', breeder.uid, 'MyChats');
			const destChatRef = doc(destChatCollection, chatUserId);

			if (snapShot.exists()) {
				const messegeCollection = collection(db, 'Messages', snapShot.data().messageId, 'MyMessages');
				const newMessageRef = doc(messegeCollection);
				await setDoc(newMessageRef, {
					messageText: messageInput,
					senderId: user.uid,
					// senderName: user.isBuyer
					// 	? `${user.firstName} ${user?.lastName}`
					// 	: user.businessName
					// 	? user.businessName
					// 	: user.registryName,
					senderName: user.isBuyer
						? user.name
							? user.name
							: `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: user.businessName
						? user.businessName
						: user.registryName
						? user.registryName
						: user.firstName || user.lastName
						? `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: '',
					senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
					sentDate: serverTimestamp()
				});
				await updateDoc(chatRef, {
					isRead: false,
					lastMessage: messageInput,
					senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
					senderName: breederInfo?.businessName ? breederInfo?.businessName : breederInfo?.registryName,
					sentDate: serverTimestamp()
				});
				await updateDoc(destChatRef, {
					isRead: false,
					lastMessage: messageInput,
					senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
					senderName: user.isBuyer
						? user.name
							? user.name
							: `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: user.businessName
						? user.businessName
						: user.registryName
						? user.registryName
						: user.firstName || user.lastName
						? `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: '',
					sentDate: serverTimestamp()
				});
			} else {
				const messagesRef = doc(collection(db, 'Messages'));
				const messegeCollection = collection(db, 'Messages', messagesRef.id, 'MyMessages');
				const newMessageRef = doc(messegeCollection);
				await setDoc(newMessageRef, {
					messageText: messageInput,
					senderId: user.uid,
					senderName: user.isBuyer
						? user.name
							? user.name
							: `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: user.businessName
						? user.businessName
						: user.registryName
						? user.registryName
						: user.firstName || user.lastName
						? `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
						: '',
					senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
					sentDate: serverTimestamp()
				});
				await setDoc(
					chatRef,
					{
						isRead: false,
						lastMessage: messageInput,
						senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
						senderName: breeder.businessName ? breeder.businessName : breeder.registryName,
						sentDate: serverTimestamp(),
						userId: breeder.uid,
						messageId: messagesRef.id
					},
					{ merge: true }
				);
				await setDoc(
					destChatRef,
					{
						isRead: false,
						lastMessage: messageInput,
						senderImage: user.isBuyer ? user?.profilePic ?? '' : user?.businessPic ?? '',
						senderName: user.isBuyer
							? user.name
								? user.name
								: `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
							: user.businessName
							? user.businessName
							: user.registryName
							? user.registryName
							: user.firstName || user.lastName
							? `${user.firstName ? user.firstName : ''} ${user?.lastName ? user.lastName : ''}`
							: '',
						sentDate: serverTimestamp(),
						userId: user.uid,
						messageId: messagesRef.id
					},
					{ merge: true }
				);
			}
			message.success(`Successfully sent a message to breeder.`);
			onCancel();
			onForceCb();
		} catch (error) {
			console.log(error);
			message.error(`Something wen't wrong in sending a message.`);
		}
	};

	const onReset = () => {
		setMessageInput('');
		setErrorMsg('');
	};

	return (
		<Modal
			okText="Send Message"
			cancelButtonProps={{ type: 'ghost' }}
			title="Send Message"
			open={opened}
			onCancel={onCancel}
			centered
			onOk={onSendMessage}
			afterClose={onReset}
			className="contactBreederPopup"
		>
			{isLoading ? <div>loading breeder Info</div> : null}
			{breederInfo && (
				<div className="contactBreederInfo">
					<AnimalBreederInfo
						breederId={breederInfo?.uid}
						name={breederInfo?.businessName}
						avatarUrl={
							breederInfo?.avatar ??
							breederInfo?.businessPic ??
							breederInfo?.coverPic ??
							require(`../../assets/images/vectors/${randomVector}.png`)
						}
						description={breederInfo?.aboutBusiness}
						address={breederInfo?.businessAddress}
						verified={breederInfo?.isVerified}
					/>
				</div>
			)}
			<div className="contactBreederInput">
				<Typography.Title className="contactBreederInputTxt" level={5}>
					Message
				</Typography.Title>
				<Input.TextArea
					status={errorMsg ? 'error' : ''}
					value={messageInput}
					onChange={(e: any) => setMessageInput(e.target.value)}
					rows={5}
				/>
				{errorMsg ? <small className="errorMsg">{errorMsg}</small> : null}
			</div>
		</Modal>
	);
};

export default ContactBreederPopup;
